import { Component, OnInit, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Item } from '../../models/item';
import { Router } from '@angular/router';
import { Catalog } from 'src/app/models/catalog';
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {

    items1: Array<Item> = [];
    items2: Array<Item> = [];
    items: Item[] = [];
    error: string;
    //    notice: Notice;
    catalogs: Catalog[];

    header: string;
    oldCategoryId = 0;
    interval: any;
    thumbnail: any;
    catalogName: String;
    webSocketAPI: WebSocketAPI;
    stompClient: any;
    topic = '/topic/display';
    constructor(private router: Router, private itemSrv: ItemService) {
    }

    ngOnInit() {
        console.log('MenuComponent: ngOnInit 12');
        this.getCatelogs();
        this.getActiveItems();
        this.webSocketAPI = new WebSocketAPI();
        this.interval = setInterval(() => {
            this.reWebSocketConnect();
        }, 600000);
    }
    ngOnDestroy() {
        console.log('ngOnDestroy');
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    reWebSocketConnect() {
        this.stompClient = this.webSocketAPI.connect();
        const _this = this;
        _this.stompClient.connect({}, function (frame) {
            _this.stompClient.subscribe(_this.topic, function (sdkEvent) {
                _this.handleMessage(JSON.parse(sdkEvent.body));
            });
        }, _this.stompClient.errorCallBack);
    }

    getActiveItems(): void {
        this.itemSrv.getActiveItems(false).subscribe((resp: Item[]) => {
            this.handleMessage(resp);
        },
            error => console.log('Error :: ' + error)
        );
    }
    /*     getActiveNotice(): void {
            this.itemSrv.getActiveNotice()
                .subscribe((resp: Notice) => {
                    this.notice = resp;
                    this.getByteByString(this.notice.imageObj);
                },
                    error => console.log('Error :: ' + error)
                );
        } */
    getCatelogs(): void {
        this.itemSrv.getCatalogs()
            .subscribe((resp: Catalog[]) => {
                this.catalogs = resp;
            },
                error => console.log('Error :: ' + error)
            );
    }
    getCatalog(categoryId: number): String {
        this.catalogName = '';
        if (this.catalogs != null) {
            this.catalogs.forEach((value) => {
                if (categoryId === value.id) {
                    this.catalogName = value.catelogName;
                }
            });
        }
        return this.catalogName;
    }
    handleMessage(resp: Item[]) {
        let k = 2, oldCategoryId = 0;
        let itemRoot = new Item();

        const first: Array<Item> = [];
        const second: Array<Item> = [];
        console.log(' handle Message ');
        if (resp != null) {
            for (const item of resp) {
                if (oldCategoryId !== item.categoryId) {
                    itemRoot = new Item();
                    oldCategoryId = item.categoryId;
                    itemRoot.itemName = this.getCatalog(oldCategoryId);
                    itemRoot.price = 0;
                    if (k === 1) {
                        k = 2;
                        second.push(itemRoot);
                    } else {
                        k = 1;
                        first.push(itemRoot);
                    }
                }
                if (k === 1) {
                    first.push(item);
                } else {
                    second.push(item);
                }
            }
            this.items1 = first;
            this.items2 = second;
        }
    }
}
