import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  users: User[];
  error: string;
  columns = ['Last Name', 'First Name', 'Phone Number', 'Email', 'Password', 'Status', 'Created Date', 'Updated Date'];

  constructor(private router: Router, private usrSrv: ItemService) {
    console.log('UserComponent: constructor');
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }
  }

  ngOnInit(): void {
    let dNow = new Date();
    let expiry = sessionStorage.getItem('expiry');
    if (Number(expiry) <= (Number(dNow.getMilliseconds) + 3600)) {
      this.router.navigateByUrl('/home');
    }
    this.getUsers();
  }

  convertDate(number): String {
    let dateString = "Today's date is: ";
    let newDate = new Date(number);
    dateString = (newDate.getMonth() + 1) + "/";
    dateString += newDate.getDate() + "/";
    dateString += newDate.getFullYear();
    console.log('UserComponent: convertDate ' + dateString);
    return dateString;
  }
  convertStatus(status): String {
    console.log('UserComponent: convertStatus ' + status);
    return status == 'A' ? 'Active' : 'Inactive';
  }
  getUsers(): void {
    console.log('UserComponent: getUsers');
    this.usrSrv.getUsers()
      .subscribe((resp: User[]) => {
          this.users = resp;
          sessionStorage.setItem('users', JSON.stringify(resp));
        },
        error => console.log('Error :: ' + error)
      )
  }
}