import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OrderDisplay } from '../../models/orderdisplay';
import { ItemService } from 'src/app/services/item.service';
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';

@Component({
  selector: 'app-deliveryall',
  templateUrl: './delivery.component.html',
  styleUrls: ['./report.component.css']
})

export class DeliveryAllComponent implements OnInit, OnDestroy, AfterViewChecked {

  orderDisplay: OrderDisplay[];
  orderDisplay1: OrderDisplay[];
  orderDisplay2: OrderDisplay[];
  orderDisplay3: OrderDisplay[];
  orderdisplay: OrderDisplay;
  interval: any;
  rowSize: number;
  rowIncrease: number;
  dataStr: String;
  audio: any;
  timeCount: number;
  webSocketAPI: WebSocketAPI;
  stompClient: any;
  topic: string = '/topic/delivery';

  constructor(private router: Router, private srv: ItemService) {
    this.audio = new Audio();
    this.getDeliveryRefresh();
  }

  ngOnInit() {
    this.rowSize = 3;
    this.rowIncrease = 0;
    this.timeCount = 0;
    this.webSocketAPI = new WebSocketAPI();
    this.reWebSocketConnect();
    this.interval = setInterval(() => {
      this.reWebSocketConnect();
    }, 600000);
  }
  ngOnDestroy(): void {
    if (this.interval) {
        clearInterval(this.interval);
    }
  }
  reWebSocketConnect() {
    this.stompClient = this.webSocketAPI.connect();
    const _this = this;
    _this.stompClient.connect({}, function (frame) {
      _this.stompClient.subscribe(_this.topic, function (sdkEvent) {
        if (sdkEvent != null) {
          _this.handleMessage(JSON.parse(sdkEvent.body));
        } else {
          _this.handleMessage(null);
        }
      });
    }, _this.stompClient.errorCallBack);
  }
  ngAfterViewChecked() {
    this.getEnable();
  }
  customerNumber(saleItemId: number): String {
    return saleItemId.toString().substring(saleItemId.toString().length - 2);
  }
  customerName(customerName: string): String {
    return customerName.length > 30 ? customerName.substring(0, 30) : customerName.padEnd(30);
  }
  playAudio(saleItemId: number) {
    this.audio.src = './assets/audio/' + this.customerNumber(saleItemId) + '.mp3';
    this.audio.load();
    this.audio.play();
  }
  onSave(saleItemId: number, event): void {
    if (event.target.checked) {
      (<HTMLInputElement>document.getElementById('cus' + saleItemId)).setAttribute("class", "strickthough");
      (<HTMLInputElement>document.getElementById('qty' + saleItemId)).setAttribute("class", "strickthough");
    } else {
      (<HTMLInputElement>document.getElementById('cus' + saleItemId)).setAttribute("class", "nonstrickthough");
      (<HTMLInputElement>document.getElementById('qty' + saleItemId)).setAttribute("class", "nonstrickthough");
    }
    this.srv.saveSaleItem(saleItemId, event.target.checked ? 'Y' : 'N').subscribe();
    if (this.orderDisplay != null) {
      for (const order of this.orderDisplay) {
        for (const entry of order.items) {
          if (entry.id === saleItemId) {
            entry.deliveryStatus = event.target.checked ? 'Y' : 'N';
          }
        }
      }
    }
    this.timeCount = 0;
  }
  reprocessSale(saleId: number, typ: String): void {
    this.srv.reprocessSale(saleId, typ).subscribe();
  }
  getDeliveryRefresh() {
    this.orderDisplay1 = [];
    this.orderDisplay2 = [];
    this.orderDisplay3 = [];
    this.timeCount = 0;
    this.srv.getDeliveryAll()
      .subscribe((resp: OrderDisplay[]) => {
        this.handleMessage(resp);
      },
        error => console.log('Error :: ' + error)
      );
  }
  getEnable() {
    if (this.orderDisplay != null) {
      for (const order of this.orderDisplay) {
        for (const entry of order.items) {
          const ent1 = <HTMLInputElement>document.getElementById('cus' + entry.id);
          if (entry.deliveryStatus === 'Y' && ent1 != null) {
            (<HTMLInputElement>document.getElementById('cus' + entry.id)).setAttribute("class", "strickthough");
            (<HTMLInputElement>document.getElementById('qty' + entry.id)).setAttribute("class", "strickthough");
          }
        }
      }
    }
  }
  handleMessage(resp: OrderDisplay[]) {
    this.orderDisplay1 = [];
    this.orderDisplay2 = [];
    this.orderDisplay3 = [];
    this.orderDisplay = resp;
    this.interval = 0;
    this.rowIncrease = 1;
    if (resp != null) {
      for (let i = 0; i < resp.length; i++) {
        this.orderdisplay = resp[i];
        if (this.orderdisplay.appType === 'K') {
           this.orderdisplay.appType = 'Online (' + this.orderdisplay.pickupTime+')';
        } else {
           this.orderdisplay.appType = '';
        }
        if (this.rowIncrease == 1) {
          this.orderDisplay1.push(resp[i]);
        } else if (this.rowIncrease == 2) {
          this.orderDisplay2.push(resp[i]);
        } else {
          this.orderDisplay3.push(resp[i]);
        }
        if (this.rowIncrease > 2) {
          this.rowIncrease = 0;
        }
        this.rowIncrease += 1;
      }
    }

  }
}
