export class Item {

    id: number;
    itemName: String;
    showInd: String;
    summaryShowInd: String;
    mobileShowInd: String;
    categoryId: number;
    categoryName: String;
    price: Number;
    description: String;
    orderId: number;
    imageObj: String;
    status: String;
    createdDate: Date;
    updatedDate: Date;

    constructor(id?: number, itemName?: string, summaryShowInd?: string, showInd?: string, mobileShowInd?: string, categoryId?: number,
     categoryName?: string, price?: string, description?: string, orderId?: string, imageObj?: string, status?: string, createdDate?: Date,
     updatedDate?: Date) { }
}
