import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Grocery } from 'src/app/models/grocery';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-grocery',
  templateUrl: './grocery.component.html',
  styleUrls: ['./grocery.component.css']
})
export class GroceryComponent implements OnInit {

  grocerys: Grocery[];
  error: string;
  constructor(private router: Router, private usrSrv: ItemService) {
    console.log('GroceryComponent: constructor');
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/grocery']);
        }
  }

  ngOnInit(): void {
    const dNow = new Date();
    const expiry = sessionStorage.getItem('expiry');
    if (Number(expiry) <= (Number(dNow.getMilliseconds) + 3600)) {
      this.router.navigateByUrl('/home');
    }
    this.getGrocerys();
  }
  convertStatus(status): String {
    console.log('GroceryComponent: convertStatus ' + status);
    return status == 'A' ? 'Active' : 'Inactive';
  }
  getGrocerys(): void {
    console.log('GroceryComponent: getUsers');
    this.usrSrv.getGrocerys()
      .subscribe((resp: Grocery[]) => {
          this.grocerys = resp;
          console.log('GroceryComponent: convertStatus ' + JSON.stringify(resp));
        },
        error => console.log('Error :: ' + error)
      );
  }
}
