import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SaleDetails } from '../../models/saledetails';
import { ItemService } from 'src/app/services/item.service';
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';

@Component({
  selector: 'app-dosa',
  templateUrl: './details.component.html',
  styleUrls: ['./report.component.css']
})
export class DosaComponent implements OnInit, OnDestroy {

  saledetails: SaleDetails[];
  salesItemIds: number[] = [];
  interval: any;
  webSocketAPI: WebSocketAPI;
  stompClient: any;
  topic: string = '/topic/dosa';
  constructor(private router: Router, private srv: ItemService) {
    this.getSaleDetailsRefresh();
  }

  ngOnInit() {
    this.webSocketAPI = new WebSocketAPI();
    this.reWebSocketConnect();
    this.interval = setInterval(() => {
      this.reWebSocketConnect();
    }, 600000);
  }
  ngOnDestroy(): void {
    if (this.interval) {
        clearInterval(this.interval);
    }
  }
  reWebSocketConnect() {
    this.stompClient = this.webSocketAPI.connect();
    const _this = this;
    _this.stompClient.connect({}, function (frame) {
      _this.stompClient.subscribe(_this.topic, function (sdkEvent) {
        if (sdkEvent != null) {
          _this.saledetails = JSON.parse(sdkEvent.body);
        } else {
          _this.saledetails = [];
        }
      });
    }, _this.stompClient.errorCallBack);
  }

  addList(saleItemId: number): void {
    this.salesItemIds.push(saleItemId);
  }
  onSave(saleItemId: number, typ: String): void {
    this.srv.saveSaleItem(saleItemId, typ).subscribe();
    this.getSaleDetailsRefresh();
  }
  customerNumber(saleItemId: number): String {
    return saleItemId.toString().substring(saleItemId.toString().length - 2);
  }
  getSaleDetailsRefresh() {
    this.srv.getDosa()
      .subscribe((resp: SaleDetails[]) => {
        for (const s of resp) {
            if ('K' === s.appType) {
               s.togo = 'Online'
            }
        }
        this.saledetails = resp;
      },
        error => console.log('Error :: ' + error)
      );
  }
}
