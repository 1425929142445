import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Item } from '../../models/item';
import { Order } from '../../models/order';
import { Sales } from '../../models/sales';
import { PrintReceipt } from '../../models/printreceipt';
import { Router } from '@angular/router';
import { RunnerComponent } from './../runner.component';
import { OrderLite } from 'src/app/models/orderlite';


declare function setSaleElementEnabled(): any;
declare function swipeParser(): any;
declare function setElementEnabled(): any;
declare function setElementDisEnabled(): any;

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.css']
})
export class OrderComponent extends RunnerComponent implements OnInit, OnDestroy, AfterContentChecked {

    items: Item[];
    order: Order;
    orderLite: OrderLite;
    errorMsg: string;
    cartObj: Sales[];
    totalCount: number = 0;
    header: string;
    oldCategoryId; number = 0;
    isOne: boolean = false;
    isTwo: boolean = false;
    isThree: boolean = false;
    isFour: boolean = false;
    isFive: boolean = false;
    isSix: boolean = false;
    isSeven: boolean = false;
    isEight: boolean = false;
    interval: any;
    creditCard: string;
    creditCardOption: string;
    expiration: string;
    name: string;
    invoice: number;
    invoiceDate: string;
    printRpt: PrintReceipt;

    constructor(private router: Router, private itemSrv: ItemService) {
        super('From OrderComponent', 'ORDER', '');
        //  console.log('OrderComponent: constructor ');
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }
    }

    ngOnInit() {
        //  console.log('OrderComponent: ngOnInit ');
        setElementDisEnabled();
        this.getActiveItems();
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');

        this.printRpt = new PrintReceipt();
        this.printRpt.printerOption = localStorage.getItem('PrintOption');
        this.creditCardOption = localStorage.getItem('creditCardOption');
        this.printRpt.printCount = Number(localStorage.getItem('PrintCount'));
        this.printRpt.printerName = localStorage.getItem('PrinterName');
    }
    ngOnDestroy() {
        //  console.log('HomeComponent: ngOnDestroy');
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
    }
    ngAfterContentChecked(): void {
        //        this.changeDetector.detectChanges();
    }
    getActiveItems(): void {
        this.itemSrv.getActiveItems(false)
            .subscribe((resp: Item[]) => {
                this.items = resp;
                if (resp != null) {
                    this.isOne = false;
                    this.isTwo = false;
                    this.isThree = false;
                    this.isFour = false;
                    this.isFive = false;
                    this.isSix = false;
                    this.isSeven = false;
                    this.isEight = false;
                    for (const item of resp) {
                        if (item.categoryId == 1) {
                            this.isOne = true;
                        } else if (item.categoryId == 2) {
                            this.isTwo = true;
                        } else if (item.categoryId == 3) {
                            this.isThree = true;
                        } else if (item.categoryId == 4) {
                            this.isFour = true;
                        } else if (item.categoryId == 5) {
                            this.isFive = true;
                        } else if (item.categoryId == 6) {
                            this.isSix = true;
                        } else if (item.categoryId == 7) {
                            this.isSeven = true;
                        } else if (item.categoryId == 8) {
                            this.isEight = true;
                        }
                    }
                }
            },
                error => console.log('Error :: ' + error)
            );
    }
    addItems(itemId: number, itemName: string, price: number, togo: string): void {
        // console.log('addItems: ' + itemId + " : " + itemName + " : " + price);
        if (this.cartObj != null) {
            let isAdded: boolean = false;
            this.cartObj.forEach(element => {
                if (element.itemId == itemId) {
                    element.quantity += 1;
                    isAdded = true;
                }
            });
            if (!isAdded) {
                const cart = new Sales();
                cart.quantity = 1;
                cart.itemId = itemId;
                cart.price = price;
                cart.itemName = itemName;
                cart.togo = togo;
                this.cartObj.push(cart);
            }
        } else {
            this.cartObj = [];
            const cart = new Sales();
            cart.quantity = 1;
            cart.itemId = itemId;
            cart.price = price;
            cart.itemName = itemName;
            cart.togo = togo;
            this.cartObj.push(cart);
        }
        let val = 0;
        this.cartObj.forEach(obj => {
            val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            //              console.log('total count: ' + this.totalCount + " : " + val);
        });
        this.totalCount = val;
    }
    selectCC(modal): void {
        let val = 0;
        if (this.cartObj != null && this.cartObj.length > 0) {
            this.cartObj.forEach(obj => {
                val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            });
            this.totalCount = val.valueOf();
            this.resetModel();
            modal.show();
        }
    }
    selectEMV(): void {
        let val = 0;
        if (this.cartObj != null && this.cartObj.length > 0) {
            this.cartObj.forEach(obj => {
                val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            });
            this.totalCount = val.valueOf();
            this.resetModel();
        }
    }
    setToGoButton(itemId: number) {
        //  console.log('setToGoButton: ' + itemId);
        this.cartObj.forEach(obj => {
            if (obj.itemId == itemId) {
                //  console.log('setToGoButton:1 ' + itemId + '  ' + obj.togo);
                if (obj.togo == 'Y') {
                    obj.togo = 'N';
                } else {
                    obj.togo = 'Y';
                }
                //  console.log('setToGoButton:2 ' + itemId + '  ' + obj.togo);
            }
            //  console.log('setToGoButton:3 ' + itemId);
        });
    }
    selectCashOrPayPal(salesType: string): void {
        let val = 0;
        this.order = new Order();
        this.order.printerOption = this.printRpt.printerOption;
        this.order.printerName = this.printRpt.printerName;
        this.order.salesType = salesType;
        this.order.applicationType = 'C';
        this.order.sales = [];
        let sales: Sales;
        this.cartObj.forEach(obj => {
            sales = new Sales();
            sales.itemId = obj.itemId;
            sales.quantity = obj.quantity;
            sales.price = obj.price;
            sales.itemName = obj.itemName;
            val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            sales.togo = obj.togo;
            this.order.sales.push(sales);
        });
        this.totalCount = val.valueOf();
        this.order.salesAmount = this.totalCount;
        //  console.log('OrderComponent: ' + JSON.stringify(this.order));
        this.itemSrv.saveOrder(this.order)
            .subscribe((resp: Order) => {
                if (this.printRpt.printerOption === 'L') {
                    this.orderLite = resp;
                    this.printReceipt(null);
                } else {
                    this.pageRefresh();
                }
            },
                error => {
                    this.errorMsg = 'Invalid onSave';
                    //  console.log('Error :: ' + this.errorMsg);
                }
            );
    }
    delete(itemId: number) {
        const cartObj = [];
        let val = 0;
        this.cartObj.forEach(obj => {
            if (obj.itemId != itemId) {
                cartObj.push(obj);
                val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            }

        });
        this.cartObj = cartObj;
        this.totalCount = val.valueOf();
        sessionStorage.setItem('cartObj', JSON.stringify(cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + "");
    }
    negativeCount(itemId: number) {
        let val = 0;
        let isDelete = false;
        this.cartObj.forEach(obj => {
            if (obj.itemId == itemId) {
                if (obj.quantity == 1) {
                    isDelete = true;
                } else {
                    obj.quantity = obj.quantity - 1;
                    val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
                    //  console.log('total count: ' + this.totalCount + ' : ' + val);
                }
            } else {
                val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            }
        });
        if (isDelete) {
            this.delete(itemId);
        } else {
            this.totalCount = val.valueOf();
        }
        sessionStorage.setItem('cartObj', JSON.stringify(this.cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + "");
    }
    addItem(itemId: number): void {
        this.cartObj.forEach(element => {
            if (element.itemId == itemId) {
                element.quantity += 1;
            }
        });
        let val = 0;
        this.cartObj.forEach(obj => {
            val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            //  console.log('total count: ' + this.totalCount + " : " + val);
        });
        this.totalCount = val;
        sessionStorage.setItem('cartObj', JSON.stringify(this.cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + "");
    }
    resetModel(): void {
        const isCardStatus = setSaleElementEnabled();
        setElementEnabled();
        this.errorMsg = null;
    }

    reset(): void {
        this.cartObj = null;
        this.order = null;
        this.totalCount = null;
        this.oldCategoryId = null;
        this.resetModel();
        setElementDisEnabled();
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
    }
    pageRefresh() {
        this.reset();
    }
    exitModel(): void {
        setElementDisEnabled();
        this.errorMsg = null;
    }
    onSave(modal): void {
        const isCardStatus = swipeParser();
        if (!isCardStatus) {
            this.errorMsg = 'Please try again';
            return;
        }
        this.order = new Order();
        this.order.creditCard = (<HTMLInputElement>document.getElementById("cardNumber")).value;
        this.order.expiryDate = (<HTMLInputElement>document.getElementById("expiration")).value;
        this.order.nameOnCard = (<HTMLInputElement>document.getElementById("cardName")).value;
        this.order.salesAmount = this.totalCount;
        this.order.salesType = 'C';
        this.order.applicationType = 'C';
        //  console.log('1.total count: ' + this.order.creditCard + " : " + this.order.creditCard.trim.length);
        //  console.log('2.total count: ' + this.order.expiryDate);
        //  console.log('3.total count: ' + this.order.nameOnCard);
        //  console.log('4.total count: ' + this.order.salesAmount);
        if (this.order.creditCard == null || this.order.creditCard.length < 8) {
            this.errorMsg = 'Invalid credit card number';
            return;
        }
        if (this.order.nameOnCard == null || this.order.nameOnCard.length < 4) {
            this.errorMsg = 'Invalid name';
            return;
        }
        if (this.order.expiryDate == null || this.order.expiryDate.length < 6) {
            this.errorMsg = 'Invalid expiry date';
            return;
        }
        if (this.order.salesAmount == null || this.order.salesAmount < 0) {
            this.errorMsg = 'Invalid amount';
            return;
        }
        this.order.sales = [];
        let sales: Sales;
        this.order.printerOption = this.printRpt.printerOption;
        this.order.printerName = this.printRpt.printerName;
        this.order.paymentCategoryId = 1;
        this.cartObj.forEach(obj => {
            sales = new Sales();
            sales.itemId = obj.itemId;
            sales.quantity = obj.quantity;
            sales.price = obj.price;
            sales.togo = obj.togo;
            sales.itemName = obj.itemName;
            this.order.sales.push(sales);
        });

        //  console.log("OrderComponent: " + JSON.stringify(this.order));
        this.itemSrv.saveOrder(this.order)
            .subscribe(resultArray => {
                if (this.printRpt.printerOption === 'L') {
                    this.orderLite = resultArray;
                    this.printReceipt(modal);
                } else {
                    this.pageRefresh();
                }
                if (modal != null) {
                    modal.hide();
                }
            },
                error => {
                    this.errorMsg = 'Not able to process payment ' + error.message;
                    ;
                    //  console.log('Error01 :: ' + this.errorMsg + ' : ');
                    setElementEnabled();
                    return;
                }
            );
    }
    onSaveEmv(modal): void {

        this.order = new Order();
        this.order.salesAmount = this.totalCount;
        this.order.sales = [];
        let sales: Sales;
        this.order.printerOption = this.printRpt.printerOption;
        this.order.printerName = null;
        this.order.salesType = 'C';
        this.order.applicationType = 'C';
        this.order.paymentCategoryId = 1;
        this.errorMsg = '';
        this.cartObj.forEach(obj => {
            sales = new Sales();
            sales.itemId = obj.itemId;
            sales.quantity = obj.quantity;
            sales.price = obj.price;
            sales.togo = obj.togo;
            sales.itemName = obj.itemName;
            this.order.sales.push(sales);
        });

        //  console.log("onSaveEmv: " + JSON.stringify(this.order));
        this.itemSrv.sendEmvOrder(this.order)
            .subscribe((resp: Boolean) => {
                modal.hide();
                this.pageRefresh();
            },
                error => {
                    this.errorMsg = 'Not able to process payment... please retry';
                    //  console.log('Error0 :: ' + this.errorMsg + ' : ' + error);
                }
            );
    }
    printReceipt(modal): void {
        this.printRpt.id = this.orderLite.id;
        this.printRpt.createdDate = this.orderLite.createdDate;
        this.printRpt.salesAmount = this.order.salesAmount;
        this.printRpt.sales = this.order.sales;
        if (this.printRpt.printerOption === 'L') {
            this.printRpt.printerName = null;
        }
        this.itemSrv.printReceipt(this.printRpt).subscribe();
        if (modal != null) {
            modal.hide();
        }
        this.pageRefresh();
    }
    checkStatus(categoryId: number): boolean {
        //        console.log('Ramesh: checkStatus ' );
        if (this.oldCategoryId === categoryId) {
            return false;
        }
        if (categoryId === 1) {
            this.header = 'Tiffin';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 2) {
            this.header = 'Dosa';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 3) {
            this.header = 'Rice';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 4) {
            this.header = 'Drinks';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 5) {
            this.header = 'Snacks';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 6) {
            this.header = 'Sweets';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 7) {
            this.header = 'Pickles';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 8) {
            this.header = 'Batter';
            this.oldCategoryId = categoryId;
            return true;
        }
        return false;
    }
}
