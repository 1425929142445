import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    role: String;

    constructor(private router: Router) {}

    ngOnInit() {
        this.role = sessionStorage.getItem('role');
        console.log('HomeComponent: ngOnInit');
    }
}
