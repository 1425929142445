import { HttpErrorResponse} from '@angular/common/http';


declare var printElem: any;
declare var printElemWithHeader: any;
declare var printElem: any;
declare var jqueryPrint: any;
declare var $: any;


export class RunnerComponent {
  statusText: string;
  validationError: string;
  loading = true;
  total = 0;
  defTitle: string;
  title: string;
  source: string;
  visible: boolean = true;
  failure: string;
  detailLoading = false;
  
  constructor(src: string, dt: string, val: string) {
      this.defTitle = dt;
      this.source = src;
      this.title = dt;
      this.total = 0;
      this.loading = true;
      this.validationError = val;
      this.visible = true;
  }
  handleError(err: HttpErrorResponse) {
      if (err.status == 200 || err.status == 404) {
          this.setStatusText('0 Results found.');
          this.title = this.defTitle + ' (' + this.total + ')';    
      } else if (err.status == 206) {
          this.setStatusText('Query is still running ...');
      } else if (err.status == 503 || err.status == 0) {
          this.failure = 'ERROR';
          this.setStatusText("Service is not available at this time.");
          this.title = this.defTitle + ' (<font color="red">ERROR</font>)';
      } else if (err.status == 403) {
          this.failure = 'ERROR';
          this.setStatusText("The user does not have necessary permissions to access this service.");
          this.title = this.defTitle + ' (<font color="red">ERROR</font>)';
      } else if (err.status == 401) {
          this.failure = 'ERROR';
          this.setStatusText("Missing Authorization Header. Could not validate user's permissions to access this service.");
          this.title = this.defTitle + ' (<font color="red">ERROR</font>)';
      } else {
          console.log(err);
          if (err.error && err.error.message) {
              console.log('err.error.message --> ' + err.error.message);
              this.setStatusText(err.error.message);
          } else if (err.message) {
              console.log('err.message --> ' + err.message);
              this.setStatusText(err.message);
          } else if (err.error) {
              console.log('err.error --> ' + err.error);
              this.setStatusText(err.error);
          } else {
              this.setStatusText("System encountered an error while handling the request. Please check the criteria and try again ...");
          }
          
          this.failure = 'ERROR';
          this.title = this.defTitle + ' (<font color="red">ERROR</font>)';
      }
      
      if (err.status == 206) {
          this.title = this.defTitle + ' (Running)';
      } else {
          this.total = 0;
          this.loading = false;
      }
  }
  setStatusText(text: string) {
      this.statusText = text;
  }
  public print(selector: string, header: boolean) {
      setTimeout( () => {
          if (header) {
              printElemWithHeader(selector);
          } else {
              printElem(selector);
          }
      }, 1000);
  }
  public printElems (selector: string, data: string) {
    setTimeout( () => {
        printElem(selector, data);
    }, 1000);
  }
  public jqueryPrintElems (selector: string, data: string) {
    setTimeout( () => {
        jqueryPrint(selector, data);
    }, 1000);
  }
  public jqueryPrints (selector: string, data: string) {
    setTimeout( () => {
        jqueryPrint(selector, data);
    }, 1000);
  }
}
