import { Component, OnInit, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Order } from '../../models/order';
import { Router } from '@angular/router';
import { PrintReceipt } from 'src/app/models/printreceipt';
import { OrderLite } from 'src/app/models/orderlite';
import { PickupDay } from 'src/app/models/pickupday';

declare function swipeParserStr(s: String): any;
@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./order.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy {
    errorMsg: string;
    successMsg: string;
    cardNumber: string;
    expiration: string;
    cardName: string;
    email: string;
    pickupDay: String;
    pickupTime: string;
    pickupDays: PickupDay[];
    pickupTimes: PickupDay[];
    order: Order;
    orderLite: OrderLite;
    salesAmount: number;
    invoice: number;
    printRpt: PrintReceipt;

    constructor(private router: Router, private itemSrv: ItemService) {
        this.order = new Order();
        this.resetModel();
        console.log('PaymentComponent: constructor ');
        this.salesAmount = Number(sessionStorage.getItem('cartCount'));
        this.order.salesAmount = this.salesAmount;
        this.order.sales = JSON.parse(sessionStorage.getItem('cartObj'));
        this.getPickupDay();
        this.getPickupTime();
    }

    ngOnInit() {
        console.log('PaymentComponent: ngOnInit ');
        this.printRpt = new PrintReceipt();
        this.printRpt.printerOption = 'L';
        this.printRpt.printCount = 1;
        this.printRpt.printerName = 'EPSON TM-T88V Receipt';
        this.resetModel();
    }
    ngOnDestroy() {
        this.resetModel();
    }
    getPickupDay() {
        console.log('Error :: ' + this.pickupDay);
        this.itemSrv.getPickDay()
            .subscribe((resp: PickupDay[]) => {
                this.pickupDays = resp;
                this.pickupDay = resp[0].name;
                console.log('Error :: ' + this.pickupDay);
            },
                error => console.log('Error :: ' + error)
            );
    }
    getPickupTime() {
        this.itemSrv.getPickTime()
            .subscribe((resp: PickupDay[]) => {
                this.pickupTimes = resp;
            },
                error => console.log('Error :: ' + error)
            );
    }
    onSave(): void {

        this.errorMsg = null;
        const cardNumber = (<HTMLInputElement>document.getElementById('cardNumber')).value;
        this.order.salesAmount = this.salesAmount;
        console.log('4.total count: ' + this.salesAmount);
        if (cardNumber != null && cardNumber.indexOf('^') > 0) {
            console.log('cc count: ' + cardNumber.indexOf('^'));
            swipeParserStr(cardNumber);
            return;
        } else {
            this.cardName = (<HTMLInputElement>document.getElementById('cardName')).value;
            this.expiration = (<HTMLInputElement>document.getElementById('expiration')).value;
            this.order.salesAmount = this.salesAmount;
        }

        if (cardNumber == null || cardNumber.length < 14) {
            this.errorMsg = 'Invalid credit card number';
            return;
        }
        //     this.creditCard = null;
        if (this.cardName == null || this.cardName.length < 4) {
            this.errorMsg = 'Invalid name';
            return;
        }
        if (this.expiration == null || this.expiration.length < 4) {
            this.errorMsg = 'Invalid expiry date';
            return;
        }
        if (this.salesAmount == null || this.salesAmount < 0) {
            this.errorMsg = 'Invalid amount';
            return;
        }
        if (this.pickupDay == null) {
            this.errorMsg = 'Please select pick day';
            return;
        }
        if (this.pickupTime == null) {
            this.errorMsg = 'Please select pick time';
            return;
        }
        if (this.email == null || this.email.length < 2 || this.email.indexOf('@') == -1) {
            this.errorMsg = 'Invalid email';
            return;
        }
        this.order.creditCard = cardNumber;
        this.order.nameOnCard = this.cardName;
        this.order.expiryDate = this.expiration;
        this.order.email = this.email;
        this.order.pickupDay = this.pickupDay;
        this.order.pickupTime = this.pickupTime;
        this.order.salesAmount = this.salesAmount;
        this.order.salesType = 'C';
        this.order.applicationType = 'K';
        this.order.paymentCategoryId = 1;
        this.itemSrv.saveOrder(this.order)
            .subscribe((resp: Order) => {
                if (this.printRpt.printerOption === 'L') {
                    this.orderLite = resp;
                    this.printReceipt();
                }
                this.resetModel();
                sessionStorage.removeItem('cartCount');
                sessionStorage.removeItem('cartObj');
                this.successMsg = ' -- ';
                this.invoice = resp.id;
            },
                error => {
                    console.log('Error0 :: ' + error.error.message);
                    if (error != null && error.error != null && error.error.message != null) {
                        this.errorMsg = error.error.message;
                    } else {
                        this.errorMsg = 'Not able to process payment ';
                    }

                    this.successMsg = null;
                }
            );
    }
    resetModel(): void {
        this.cardNumber = null;
        this.expiration = null;
        this.cardName = null;
        this.email = null;
        this.errorMsg = null;
        this.pickupDay = null;
        this.pickupTime = null;
        this.successMsg = null;
        console.log('PaymentComponent: rModel ');
    }
    printReceipt(): void {
        this.printRpt.id = this.orderLite.id;
        this.printRpt.createdDate = this.orderLite.createdDate;
        this.printRpt.salesAmount = this.order.salesAmount;
        this.printRpt.sales = this.order.sales;
        this.itemSrv.printReceipt(this.printRpt).subscribe();
        this.resetModel();
    }
    setPickupTime(value: string) {
        this.pickupTime = value;
        console.log('setPickupTime: ' + value);
    }
    setPickupDay(value: string) {
        this.pickupDay = value;
        console.log('setPickupDay: ' + value);
    }
}
