import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  role: String;
  constructor(private router: Router) {
    console.log('ReportComponent: constructor ');
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }
  }

  ngOnInit() {
    this.role = sessionStorage.getItem('role');
  }
}
