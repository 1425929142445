import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ItemService } from '../../services/item.service';
import { Item } from '../../models/item';
import { Order } from '../../models/order';
import { Sales } from '../../models/sales';
import { PrintReceipt } from '../../models/printreceipt';
import { Router } from '@angular/router';
import { RunnerComponent } from '../runner.component';
import { OrderLite } from 'src/app/models/orderlite';
import { DomSanitizer } from '@angular/platform-browser';
import { Nophoto } from '../../services/nophoto';

declare function swipeParser(): any;
declare function setElementEnabled(): any;
declare function setElementDisEnabled(): any;

@Component({
    selector: 'app-standaloneorder',
    templateUrl: './standaloneorder.component.html',
    styleUrls: ['./order.component.css']
})

export class StandaloneOrderComponent extends RunnerComponent implements OnInit, OnDestroy {
    items: Item[];
    order: Order;
    orderLite: OrderLite;
    errorMsg: string;
    cartObj: Sales[];
    totalCount: number = 0;
    header: string;
    oldCategoryId; number = 0;
    isOne: boolean = false;
    isTwo: boolean = false;
    isThree: boolean = false;
    isFour: boolean = false;
    isFive: boolean = false;
    isSix: boolean = false;
    isSeven: boolean = false;
    isEight: boolean = false;
    interval: any;
    creditCard: string;
    expiration: string;
    name: string;
    invoice: number;
    invoiceDate: string;
    printRpt: PrintReceipt;
    noPhoto: string;

    constructor(private router: Router, private itemSrv: ItemService, private location: Location,
        private sanitizer: DomSanitizer, private nophoto: Nophoto) {
        super('From StandaloneOrderComponent', 'ORDER', '');
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
        this.noPhoto = nophoto.nophotoImage;
    }

    ngOnInit() {
        setElementDisEnabled();
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');

        this.printRpt = new PrintReceipt();
        this.printRpt.printerOption = localStorage.getItem('PrintOption');
        this.printRpt.printCount = Number(localStorage.getItem('PrintCount'));
        this.printRpt.printerName = localStorage.getItem('PrinterName');
        this.cartObj = null;
        this.pageRefresh();
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
            this.pageRefresh();
        }, 30000);
    }
    ngOnDestroy() {
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    getActiveItems(): void {
        this.itemSrv.getActiveItems(false)
            .subscribe((resp: Item[]) => {
                this.items = resp;
                if (resp != null) {
                    for (const item of resp) {
                        if (item.categoryId == 1) {
                            this.isOne = true;
                        } else if (item.categoryId === 2) {
                            this.isTwo = true;
                        } else if (item.categoryId === 3) {
                            this.isThree = true;
                        } else if (item.categoryId === 4) {
                            this.isFour = true;
                        } else if (item.categoryId === 5) {
                            this.isFive = true;
                        } else if (item.categoryId === 6) {
                            this.isSix = true;
                        } else if (item.categoryId === 7) {
                            this.isSeven = true;
                        } else if (item.categoryId === 8) {
                            this.isEight = true;
                        }
                    }
                }
            },
                error => console.log('Error :: ' + error)
            );
    }
    addItems(itemId: number, itemName: string, price: number, togo: string): void {
        if (this.cartObj != null) {
            let isAdded: boolean = false;
            this.cartObj.forEach(element => {
                if (element.itemId == itemId) {
                    element.quantity += 1;
                    isAdded = true;
                }
            });
            if (!isAdded) {
                const cart = new Sales();
                cart.quantity = 1;
                cart.itemId = itemId;
                cart.price = price;
                cart.itemName = itemName;
                cart.togo = togo;
                this.cartObj.push(cart);
            }
        } else {
            this.cartObj = [];
            const cart = new Sales();
            cart.quantity = 1;
            cart.itemId = itemId;
            cart.price = price;
            cart.itemName = itemName;
            cart.togo = togo;
            this.cartObj.push(cart);
        }
        let val = 0;
        this.cartObj.forEach(obj => {
            val = (val + (obj.price * obj.quantity).valueOf());
        });
        this.totalCount = val;
    }
    selectCC(modal) {
        let val = 0;
        if (this.cartObj != null && this.cartObj.length > 0) {
            this.cartObj.forEach(obj => {
                val = (val + (obj.price * obj.quantity).valueOf());
            });
            this.totalCount = val.valueOf();
            this.resetModel();
            modal.show();
        }
    }
    setToGoButton(itemId: number) {
        this.cartObj.forEach(obj => {
            if (obj.itemId == itemId) {
                if (obj.togo == 'Y') {
                    obj.togo = 'N';
                } else {
                    obj.togo = 'Y';
                }
            }
        });
    }
    delete(itemId: number) {
        const cartObj = [];
        let val = 0;
        this.cartObj.forEach(obj => {
            if (obj.itemId != itemId) {
                cartObj.push(obj);
                val = (val + (obj.price * obj.quantity).valueOf());
            }

        });
        this.cartObj = cartObj;
        this.totalCount = val.valueOf();
        sessionStorage.setItem('cartObj', JSON.stringify(cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + '');
    }
    negativeCount(itemId: number) {
        let val = 0;
        let isDelete: boolean = false;
        this.cartObj.forEach(obj => {
            if (obj.itemId === itemId) {
                if (obj.quantity === 1) {
                    isDelete = true;
                } else {
                    obj.quantity = obj.quantity - 1;
                    val = (val + (obj.price * obj.quantity).valueOf());
                }
            } else {
                val = (val + (obj.price * obj.quantity).valueOf());
            }
        });
        if (isDelete) {
            this.delete(itemId);
        } else {
            this.totalCount = val.valueOf();
        }
        sessionStorage.setItem('cartObj', JSON.stringify(this.cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + '');
    }
    addItem(itemId: number): void {
        this.cartObj.forEach(element => {
            if (element.itemId == itemId) {
                element.quantity += 1;
            }
        });
        let val = 0;
        this.cartObj.forEach(obj => {
            val = (val + (obj.price * obj.quantity).valueOf());
        });
        this.totalCount = val;
        sessionStorage.setItem('cartObj', JSON.stringify(this.cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + '');
    }
    reset(): void {
        this.cartObj = null;
        this.order = null;
        this.totalCount = null;
        this.errorMsg = null;
        setElementDisEnabled();
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
    }
    resetModel(): void {
        setElementEnabled();
        this.errorMsg = null;
    }
    exitModel(): void {
        setElementDisEnabled();
        this.errorMsg = null;
    }
    pageRefresh() {
        if (this.cartObj == null) {
            this.getActiveItems();
        }
    }
    onSave(modal): void {
        const isCardStatus = swipeParser();
        if (!isCardStatus) {
            this.errorMsg = 'Please try again';
            return;
        }
        this.order = new Order();
        this.errorMsg = null;
        this.order.creditCard = (<HTMLInputElement>document.getElementById('cardNumber')).value;
        this.order.expiryDate = (<HTMLInputElement>document.getElementById('expiration')).value;
        this.order.nameOnCard = (<HTMLInputElement>document.getElementById('cardName')).value;
        this.order.salesAmount = this.totalCount;
        if (this.order.creditCard == null || this.order.creditCard.length < 8) {
            this.errorMsg = 'Invalid credit card number';
            return;
        }
        if (this.order.nameOnCard == null || this.order.nameOnCard.length < 4) {
            this.errorMsg = 'Invalid name';
            return;
        }
        if (this.order.expiryDate == null || this.order.expiryDate.length < 6) {
            this.errorMsg = 'Invalid expiry date';
            return;
        }
        if (this.order.salesAmount == null || this.order.salesAmount < 0) {
            this.errorMsg = 'Invalid amount';
            return;
        }
        this.order.sales = [];
        let sales: Sales;
        this.order.printerOption = this.printRpt.printerOption;
        this.order.printerName = this.printRpt.printerName;
        this.order.salesType = 'C';
        this.order.applicationType = 'K';
        this.order.paymentCategoryId = 1;
        this.cartObj.forEach(obj => {
            sales = new Sales();
            sales.itemId = obj.itemId;
            sales.quantity = obj.quantity;
            sales.price = obj.price;
            sales.togo = obj.togo;
            sales.itemName = obj.itemName;
            this.order.sales.push(sales);
        });

        this.itemSrv.saveOrder(this.order)
            .subscribe((resp: Order) => {
                this.orderLite = resp;
                this.printReceipt();
                this.reset();
                //                $('#myModal').dialog('close');
                modal.hide();
            },
                error => {
                    this.errorMsg = 'Not able to process payment ';
                    setElementEnabled();
                }
            );
    }
    printReceipt(): void {
        this.printRpt.id = this.orderLite.id;
        this.printRpt.createdDate = this.orderLite.createdDate;
        this.printRpt.salesAmount = this.order.salesAmount;
        this.printRpt.sales = this.order.sales;
        this.itemSrv.printReceipt(this.printRpt).subscribe();
        this.reset();
    }
    checkStatus(categoryId: number): boolean {
        if (this.oldCategoryId === categoryId) {
            return false;
        }
        if (categoryId === 1) {
            this.header = 'Tiffin';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 2) {
            this.header = 'Dosa';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 3) {
            this.header = 'Rice';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 4) {
            this.header = 'Drinks';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 5) {
            this.header = 'Snacks';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 6) {
            this.header = 'Sweets';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 7) {
            this.header = 'Pickles';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 8) {
            this.header = 'Batter';
            this.oldCategoryId = categoryId;
            return true;
        }
        return false;
    }
    getByteByString(data) {
        if (data == null) {
            data = this.noPhoto;
        }
        const blob = 'data:image/jpeg;base64,' + data;
        return this.sanitizer.bypassSecurityTrustUrl(blob);
    }
}
