import { Component, OnInit, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentLink } from 'src/app/models/paymentlink';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./paymentlink.component.css']
})
export class PaymentPaymentLinkComponent implements OnInit, OnDestroy {

    errorMsg: string;
    id: number;
    orderId: number;
    salesId: number;
    key: String;
    successMsg: string;
    cardNumber: string;
    expiration: string;
    status: String;
    cardName: String;
    email: String;
    pickupDay: String;
    pickupTime: String;
    amount: number;
    link: PaymentLink;
    message: String;
    isLoaded: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private itemSrv: ItemService) {
        this.resetModel();
        console.log('PaymentComponent: constructor ');
    }

    ngOnInit() {
        console.log('PaymentComponent: ngOnInit ');
        this.isLoaded =  false;
        const selectedId = this.route.snapshot.paramMap.get('key');
        if (selectedId !== '0') {
            console.log('PaymentComponent: ngOnInit ' + selectedId);
            this.itemSrv.getPaymentlink(selectedId)
                .subscribe((resp: PaymentLink) => {
                    this.link = resp;
                    this.amount = this.link.amount;
                    this.email = this.link.email;
                    this.salesId = this.link.salesId;
                    this.orderId = this.link.orderId;
                    this.message = this.link.message;
                    this.pickupTime = this.link.pickupTime;
                    this.status = this.link.status;
                    const d = this.link.pickupDay;
                    this.pickupDay = d.substring(0, 4) + '-' + d.substring(4, 6) + '-' + d.substring(6, 8);

                },
                    error => {
                        this.errorMsg = 'Invalid ngOnInit';
                        console.log('Error :: ' + this.errorMsg);
                    }
                );
        } else {
            this.link = new PaymentLink();
        }

    }
    ngOnDestroy() {
        this.resetModel();
    }
    onSave(modal): void {

        this.errorMsg = null;
        if (this.cardNumber == null || this.cardNumber.length < 14) {
            this.errorMsg = 'Invalid credit card number';
            return;
        }
        //     this.creditCard = null;
        if (this.cardName == null || this.cardName.length < 4) {
            this.errorMsg = 'Invalid name';
            return;
        }
        if (this.expiration == null || this.expiration.length < 4) {
            this.errorMsg = 'Invalid expiry date';
            return;
        }
        if (this.errorMsg == null || this.errorMsg === '') {
           modal.show();
        }
        this.link.creditCard = this.cardNumber;
        this.link.nameOnCard = this.cardName;
        this.link.expiryDate = this.expiration;
        this.isLoaded =  true;
        this.itemSrv.savePayment(this.link)
            .subscribe(() => {
                this.successMsg = 'Saved payment ';
                this.isLoaded =  false;
                modal.hide();
            },
                error => {
                    console.log('Error0 :: ' + error.error.message);
                    this.isLoaded =  false;
                    if (error != null && error.error != null && error.error.message != null) {
                        this.errorMsg = error.error.message;
                    } else {
                        this.errorMsg = 'Not able to process payment ';
                    }
                    this.successMsg = null;
                    modal.hide();
                }
            );
    }
    resetModel(): void {
        this.cardNumber = null;
        this.expiration = null;
        this.cardName = null;
        this.errorMsg = null;
        this.successMsg = null;
         console.log('PaymentComponent: rModel ');
    }
}
