import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Item } from '../../models/item';
import { Catalog } from '../../models/catalog';
import { ItemService } from '../../services/item.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  items: Item[];
  items1: Array<Item> = [];
  items2: Array<Item> = [];
  items3: Array<Item> = [];
  items4: Array<Item> = [];
  items5: Array<Item> = [];
  items6: Array<Item> = [];
  items7: Array<Item> = [];
  items8: Array<Item> = [];
  isOne = true;
  isTwo = false;
  isThree = false;
  isFour = false;
  isFive = false;
  isSix = false;
  isSeven = false;
  isEight = false;
  error: string;
  currentId = 1;

//  columns = ['Id', 'Category Id', 'Item Name', 'Price', 'Description', 'Show', 'Status', 'Created Date', 'Updated Date'];
  constructor(private router: Router, private itemSrv: ItemService) {
    console.log('ItemComponent: constructor');
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }
  }

  ngOnInit(): void {
    const dNow = new Date();
    const expiry = sessionStorage.getItem('expiry');
    console.log('ItemComponent: constructor ' + expiry);
    if (Number(expiry) <= (Number(dNow.getMilliseconds) + 3600)) {
      this.router.navigateByUrl('/home');
    }
    console.log('ItemComponent: constructor ' + (dNow.getMilliseconds) + 3600);
    this.items = JSON.parse(sessionStorage.getItem('items'));
    if (this.items == null || this.items.length < 1) {
      this.getItems();
    } else {
      this.setItems();
    }
    this.getCatalogs();
  }
  convertDate(number): String {
    let dateString =  'Today\'s date is: ';
    const newDate = new Date(number);
    dateString = (newDate.getMonth() + 1) + '/';
    dateString += newDate.getDate() + '/';
    dateString += newDate.getFullYear();
    //  console.log('ItemComponent: convertDate ' + dateString);
    return dateString;
  }
  convertStatus(status): String {
    //   console.log('ItemComponent: convertStatus ' + status);
    return (status === 'A' ? 'Active' : 'Inactive');
  }
  convertShow(status): String {
    //   console.log('ItemComponent: convertShow ' + status);
    return (status === 'Y' ? 'Yes' : 'No');
  }
  convertCatelog(status): String {
    //   console.log('ItemComponent: convertCatelog ' + status);
    return (status === 'Y' ? 'Yes' : 'No');
  }

  getItems(): void {
    //  console.log('ItemComponent: getItems');
    this.itemSrv.getItems()
      .subscribe((resp: Item[]) => {
          this.items = resp;
          sessionStorage.setItem('items', JSON.stringify(resp));
          this.setItems();
          //         console.log('ItemComponent: getItems ' + JSON.stringify(resultArray));
        },
        error => console.log('Error :: ' + error)
      );
  }
  setItems(): void {
    if (this.items != null) {
      for (const item of this.items) {
        if (item.categoryId === 1) {
           this.items1.push(item);
        } else if (item.categoryId === 2) {
           this.items2.push(item);
        } else if (item.categoryId === 3) {
           this.items3.push(item);
        } else if (item.categoryId === 4) {
           this.items4.push(item);
        } else if (item.categoryId === 5) {
           this.items5.push(item);
        } else if (item.categoryId === 6) {
           this.items6.push(item);
        } else if (item.categoryId === 7) {
           this.items7.push(item);
        } else if (item.categoryId === 8) {
          this.items8.push(item);
        }
      }
    }
  }

  getCatalogs(): void {
    console.log('ItemComponent: getCatalogs');
    this.itemSrv.getCatalogs()
      .subscribe((resp: Catalog[]) => {
          sessionStorage.setItem('catalogs', JSON.stringify(resp));
        },
        error => console.log('Error :: ' + error)
      );
  }

  onSaveShow(id): void {
    console.log('ItemComponent: onSaveShow');
    let ids: String = '-1';
    this.items.forEach(element => {
      if (element.showInd === 'Y') {
        ids += ',' + element.id;
      }
    });
    this.itemSrv.saveShow(id);
  }
  setShowButton(id): void {
    this.itemSrv.saveShow(id).subscribe();
    for (const item of this.items) {
      if (item.id === id) {
          console.log( 'changed showInd for' + item.id + item.itemName, item.showInd);
          item.showInd = item.showInd === 'Y' ? 'N' : 'Y';
      }
    }
    sessionStorage.setItem('items', JSON.stringify(this.items));
  }

  setDiv(id: number): void {
    console.log(' Set Div : ' + id);
    this.currentId = id;
    this.isOne = id === 1 ? true : false;
    this.isTwo = id === 2 ? true : false;
    this.isThree = id === 3 ? true : false;
    this.isFour = id === 4 ? true : false;
    this.isFive = id === 5 ? true : false;
    this.isSix = id === 6 ? true : false;
    this.isSeven = id === 7 ? true : false;
    this.isEight = id === 8 ? true : false;
  }
}
