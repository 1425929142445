import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnChanges, OnInit {

  printOption: string;
  creditCardOption: string;
  printCount: number;
  printerName: string;
  deliveryRefreshInterval: number;
  constructor() {
    console.log('SetupComponent: constructor');
  }
  ngOnInit() {
    this.printerName = 'EPSON TM-T88V Receipt';
    this.printOption = localStorage.getItem('PrintOption');
    this.printCount = Number(localStorage.getItem('PrintCount'));
    this.deliveryRefreshInterval = 2;
    this.creditCardOption = localStorage.getItem('creditCardOption');
 //   this.printerName = localStorage.getItem('PrinterName');
    if (this.printOption == null || this.printOption === '') {
      this.printOption = 'L';
    }
    if (this.creditCardOption == null || this.creditCardOption === '') {
      this.creditCardOption = 'SWP'; //EMV
    }
    localStorage.setItem('PrinterName', this.printerName);
    localStorage.setItem('creditCardOption', this.creditCardOption);
    localStorage.setItem('deliveryRefreshInterval', this.deliveryRefreshInterval + '');
    
    console.log('SetupComponent: setPrinterName ' + localStorage.getItem('PrinterName'));
    console.log('SetupComponent: setPrintOption ' + localStorage.getItem('PrintOption'));
    console.log('SetupComponent: creditCardOption ' + localStorage.getItem('creditCardOption'));
    console.log('SetupComponent: setPrintCount ' + localStorage.getItem('PrintCount'));
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('SetupComponent: setPrinterName ' + localStorage.getItem('PrinterName'));
    console.log('SetupComponent: setPrintOption ' + localStorage.getItem('PrintOption'));
    console.log('SetupComponent: setPrintCount ' + localStorage.getItem('PrintCount'));
    console.log('SetupComponent: creditCardOption ' + localStorage.getItem('creditCardOption'));
    console.log('SetupComponent: setDeliveryRefreshInterval ' + localStorage.getItem('deliveryRefreshInterval'));
  }
  setPrinterName(): void {
    console.log('SetupComponent: setPrinterName ');
    localStorage.setItem('PrinterName', this.printerName);
  }
  setPrintOption(printOption: string): void {
    console.log('SetupComponent: setPrintOption ' + printOption);
    localStorage.setItem('PrintOption', printOption);
  }
  setCreditCardOption(creditCardOption: string): void {
    console.log('SetupComponent: creditCardOption ' + creditCardOption);
    this.creditCardOption = creditCardOption;
    localStorage.setItem('creditCardOption', creditCardOption);
  }
  setPrintCount(printCount: any): void {
    console.log('SetupComponent: setPrintCount ' + printCount);
    localStorage.setItem('PrintCount', printCount);
    console.log('SetupComponent: setPrinterName ' + localStorage.getItem('PrinterName'));
    console.log('SetupComponent: setPrintOption ' + localStorage.getItem('PrintOption'));
    console.log('SetupComponent: setPrintCount ' + localStorage.getItem('PrintCount'));

  }
  onSave(): void {
    localStorage.setItem('PrinterName', this.printerName);
    localStorage.setItem('PrintCount', this.printCount + ' ');
    localStorage.setItem('PrintOption', this.printOption);
    localStorage.setItem('creditCardOption', this.creditCardOption);
    localStorage.setItem('deliveryRefreshInterval', this.deliveryRefreshInterval + ' ');
    console.log('SetupComponent: setPrinterName ' + localStorage.getItem('PrinterName'));
    console.log('SetupComponent: setPrintOption ' + localStorage.getItem('PrintOption'));
    console.log('SetupComponent: setPrintCount ' + localStorage.getItem('PrintCount'));
    console.log('SetupComponent: creditCardOption ' + localStorage.getItem('creditCardOption'));
    console.log('SetupComponent: setDeliveryRefreshInterval ' + localStorage.getItem('deliveryRefreshInterval'));
  }
}
