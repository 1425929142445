export class User {

    id: number;
    userId: String;
    firstName: String;;
    lastName: String;
    phone: String;
    emailAddress: String = "ramesh@svlt.com";
    password: String;
    status: String;
    createdDate: Date;
    updatedDate: Date;

    constructor(id?: number, userId?: string, firstName?: string, lastName?: string, phone?: string, status?: string, createdDate?: Date, updatedDate?: Date, emailAddress?: string, password?: string) { }

}