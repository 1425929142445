import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ItemService } from '../../services/item.service';
import { Purchase } from 'src/app/models/purchase';
import { Grocery } from 'src/app/models/grocery';
import { PurchaseDetail } from 'src/app/models/purchasedetail';
import { KeyValue } from 'src/app/models/keyvalue';

@Component({

    selector: 'app-editpurchase',
    templateUrl: './edit.component.html',
    styleUrls: ['./purchase.component.css']

})

export class EditPurchaseComponent implements OnInit {

    purchase: PurchaseDetail;
    errorMsg: string;
    oldCategory = '';
    header: String;
    grocerys: Grocery[];
    purchases: Purchase[];
    categoryNames: KeyValue[] = [{key: 'Paper', value: 'Paper'}, {key: 'Vegatable', value: 'Vegatable'}, {key: 'Frozen', value: 'Frozen'}];
    typs: KeyValue[] = [{key: '1', value: 'Bag'}, {key: '2', value: 'Count'}, {key: '3', value: 'Can'}, {key: '4', value: 'box'}];
    constructor(private router: Router,	private route: ActivatedRoute, private itemSrv: ItemService) {
        console.log('EditPurchaseComponent: constructor');
    }

    ngOnInit() {

        const selectedId = this.route.snapshot.paramMap.get('id');
        if (selectedId != null && Number(selectedId) > 0) {
            this.itemSrv.getPurchase(Number(selectedId))
                .subscribe((resp: PurchaseDetail) => {
                    this.purchase = resp;
                    console.log('EditPurchaseComponent: subscribe ' + JSON.stringify(resp));
                },
                    error => {
                        this.errorMsg = 'Invalid onSave';
                        console.log('Error :: ' + this.errorMsg);
                    }
                );
        }
        this.getGrocerys();
    }

    onSave(): void {

        this.itemSrv.savePurchase(this.purchase)
            .subscribe((resp: PurchaseDetail) => {
                this.router.navigateByUrl('/purchase');
            },
                error => {
                    this.errorMsg = 'Invalid onSave';
                    console.log('Error :: ' + this.errorMsg);
                }
            );
    }
  getGrocerys(): void {
    console.log('GroceryComponent: getUsers');
    this.itemSrv.getGrocerys()
      .subscribe((resp: Grocery[]) => {
          this.grocerys = resp;
        },
        error => console.log('Error :: ' + error)
      );
    }
    checkStatus(category: string): boolean {
       if (category == null) {
           category = 'Paper' ;
       }
        console.log('Ramesh: checkStatus ' + category + ' : ' + this.oldCategory );
        if (this.oldCategory === category) {
            return false;
        }
        this.oldCategory = category;
        this.header = this.categoryNames.filter(function(item) {
                  return item.key === category;
        })[0].value;
    }
}
