import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Sales } from 'src/app/models/sales';
import { SalesHistory } from 'src/app/models/saleshistory';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-onlineorderbydate',
  templateUrl: './onlineorderbydate.component.html',
  styleUrls: ['./report.component.css']
})
export class OnlineOrderByDateComponent implements OnInit {

  startDate: string;
  errorMsg: String;
  salehistory: SalesHistory[];
  sales: Sales[];
  totalAmount: number;
  constructor(private router: Router, private srv: ItemService) {
    console.log('OnlineOrderByDateComponent: constructor');
  }
  ngOnInit() {
    this.startDate = null;
  }
  getSaleHistoryReport(): void {
    console.log('OnlineOrderByDateComponent: ' + this.startDate);
    sessionStorage.setItem('onlineorderStartDate', this.startDate);
    this.getSalesHistoryByDate(this.startDate);
  }
  getSalesHistoryByDate(startDate: String): void {
    this.srv.getOnlineOrderByDate(startDate)
      .subscribe((resp: SalesHistory[]) => {
        this.salehistory = resp;
      },
        error => console.log('Error :: ' + error)
      );
    sessionStorage.removeItem('orderHistoryStartDate');
  }
  getSaleItems(saleId: number): void {
    this.srv.getSaleItems(saleId).subscribe((resp: Sales[]) => {
      this.sales = resp;
      this.totalAmount = 0.00;
      for (const element of resp) {
        this.totalAmount += (element.price * element.quantity * 100) / 100;
      }
      this.totalAmount = (this.totalAmount * 100) / 100;
    },
      error => console.log('Error :: ' + error)
    );
  }
}
