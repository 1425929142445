export class Grocery {
    id: number;
    orderId: number;
    itemName: String;
    buyType: String;
    storeName: String;
    categoryName: String;
    status: String;
    // tslint:disable-next-line:max-line-length
    constructor(id?: number, orderId?: number, itemName?: string, buyType?: string, storeName?: String, categoryName?: String, status?: String) { }
}
