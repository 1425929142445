import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ItemService } from '../../services/item.service';
import { Item } from '../../models/item';
import { Catalog } from '../../models/catalog';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-edititem',
    templateUrl: './edit.component.html',
    styleUrls: ['./item.component.css']
})
export class EdititemComponent implements OnInit {

    item: Item;
    catalogs: Catalog[];
    errorMsg: string;
    thumbnail: any;
    itemName: string;
    showInd: String;
    summaryShowInd: String;
    mobileShowInd: String;
    categoryId: number;
    categoryName: String;
    price: Number;
    constructor(private router: Router,	private route: ActivatedRoute, private itemSrv: ItemService, private sanitizer: DomSanitizer) {
        console.log('EdititemComponent: constructor');
    }

    ngOnInit() {

        const selectedId = this.route.snapshot.paramMap.get('id');
        const catalogObj = JSON.parse(sessionStorage.getItem('catalogs'));
        this.item = new Item();
        this.item.showInd = 'Y';
        this.item.summaryShowInd = 'Y';
        this.item.mobileShowInd = 'Y';
        this.item.categoryId = 1;
        if (catalogObj != null) {
            this.catalogs = new Array();
            for (let i = 0; i < catalogObj.length; i++) {
                this.catalogs[i] = catalogObj[i];
            }
        }
        if (selectedId != null && Number(selectedId) > 0) {
            this.itemSrv.getItem(selectedId)
                .subscribe((resp: Item) => {
                    this.item = resp;
                    this.getByteByString(this.item.imageObj);
                    this.item.showInd = 'Y';
                    this.item.summaryShowInd = 'Y';
                },
                    error => {
                        this.errorMsg = 'Invalid onSave';
                        console.log('Error :: ' + this.errorMsg);
                    }
                );
        }
    }

    onSave(): void {

        this.itemSrv.saveItem(this.item)
            .subscribe((resp: Item) => {
                sessionStorage.setItem('items', null);
                this.router.navigateByUrl('/item');
            },
                error => {
                    this.errorMsg = 'Invalid onSave';
                    console.log('Error :: ' + this.errorMsg);
                }
            );
    }
    onDelete(): void {
        this.itemSrv.deleteItem(this.item.id);
    }
    setShowButton(str): void {
        this.item.showInd = str;
        console.log('setShowButton :: ' + this.item.showInd);
    }
    setSummaryShowIndButton(str): void {
        this.item.summaryShowInd = str;
        console.log('setSummaryShowIndButton :: ' + this.item.summaryShowInd);
    }
    setMobileShowIndButton(str): void {
        this.item.mobileShowInd = str;
        console.log('setMobileShowIndButton :: ' + this.item.mobileShowInd);
    }
    setStatusButton(str): void {
        this.item.status = str;
        console.log('setStatusButton :: ' + this.item.status);
    }
    onFileChanged(event) {
        const files = event.target.files;
        const file = files[0];

        if (files && file) {
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;
            const max_size = 20971520;
            if (file.size > max_size) {
                this.errorMsg = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
                return false;
            }
            const reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
            this.getByteByString(this.item.imageObj);
        }
    }
    _handleReaderLoaded(readerEvt) {
        const binaryString = readerEvt.target.result;
        this.item.imageObj = btoa(binaryString);  // Converting binary string data.
    }
    getByteByString(data) {
        this.thumbnail = null;
        if (data != null) {
            const blob = 'data:image/jpeg;base64,' + data;
            this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(blob);
        }
    }
}
