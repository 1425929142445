import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// tslint:disable-next-line:import-blacklist
import 'rxjs/Rx';
import { Item } from '../models/item';
import { Notice } from '../models/notice';
import { Catalog } from '../models/catalog';
import { Order } from '../models/order';
import { PrintReceipt } from '../models/printreceipt';
import 'rxjs/add/observable/throw';
import { Sales } from '../models/sales';
import { SalesHistory } from '../models/saleshistory';
import { PaymentCategory } from '../models/paymentcategory';
import { PickupDay } from '../models/pickupday';
import { User } from '../models/user';
import { LoginRequest } from '../models/loginrequest';
import { JwtResponse } from '../models/loginresponse';
import { SaleSummary } from '../models/salesummary';
import { SaleFilter } from '../models/salefilter';
import { OrderDisplay } from '../models/orderdisplay';
import { SaleDetails } from '../models/saledetails';
import {environment} from '../../environments/environment';
import { Grocery } from '../models/grocery';
import { PurchaseSummary } from '../models/purchasesummary';
import { PurchaseDetail } from '../models/purchasedetail';
import { AveryLabel } from '../models/averylabel';
import { PaymentLink } from '../models/paymentlink';

@Injectable()
export class ItemService {

    url: string;
    printUrl: string;

    constructor(private http: HttpClient) {
        this.url = environment.url;
        this.printUrl = environment.printerUrl;
 //       console.log('ItemService: constructor: ' + this.url);
 //       console.log('ItemService: constructor: ' + this.printUrl);
    }
    rePrint(salesId: Number): Observable<Order> {
//        console.log('rePrint:');
        return this.http.get<Order>(this.url + 'sale/print/' + salesId + '/printoption/' + localStorage.getItem('PrintOption'));
    }
    getActiveItems(needImage: Boolean): Observable<Item[]> {
//        console.log('ItemService getItems:');
        const url = needImage ? 'report/showimageitem' : 'report/showactive';
        return this.http.get<Item[]>(this.url + url);
    }
    getActiveNotice(): Observable<Notice> {
//        console.log('ItemService getActiveNotice:');
        return this.http.get<Notice>(this.url + 'notice/active');
    }
    getItem(selectedId: String): Observable<Item> {
//        console.log('ItemService: Item json: ' + this.url + 'item/findId/' + selectedId);
        return this.http.get<Item>(this.url + 'item/findId/' + selectedId);
    }
    getItems(): Observable<Item[]> {
//       console.log('ItemService getItems:');
        return this.http.get<Item[]>(this.url + 'item/findAll');
    }
    getSalesHistory(): Observable<SalesHistory[]> {
//       console.log('ItemService getSalesHistory:');
        return this.http.get<SalesHistory[]>(this.url + 'sale/history');
    }
    getSalesHistoryByDate(startDate: String): Observable<SalesHistory[]> {
//        console.log('ItemService getSalesHistoryByDate:');
        return this.http.get<SalesHistory[]>(this.url + 'sale/orderbydate/' + startDate);
    }
    getOnlineOrderByDate(startDate: String): Observable<SalesHistory[]> {
//        console.log('ItemService getOnlineOrderByDate:');
        return this.http.get<SalesHistory[]>(this.url + 'sale/onlineorder/' + startDate);
    }
    getSaleItems(selectedId: Number): Observable<Sales[]> {
//        console.log('ItemService getSaleItems:');
        return this.http.get<Sales[]>(this.url + 'sale/findItemId/' + selectedId);
    }
    getGrocerys(): Observable<Grocery[]> {
//        console.log('ItemService getGrocerys:');
        return this.http.get<Grocery[]>(this.url + 'grocery/findAll');
    }
    getGrocery(selectedId: Number): Observable<Grocery> {
//        console.log('ItemService getGrocery:');
        return this.http.get<Grocery>(this.url + 'grocery/' + selectedId);
    }
   getPurchases(): Observable<PurchaseSummary[]> {
//        console.log('ItemService getPurchases:');
        return this.http.get<PurchaseSummary[]>(this.url + 'grocery/purchase/findAll');
    }
   getPurchase(selectedId: Number): Observable<PurchaseDetail> {
//        console.log('ItemService getPurchase:');
        return this.http.get<PurchaseDetail>(this.url + 'grocery/purchase/' + selectedId);
    }
    getCatalogs(): Observable<Catalog[]> {
//        console.log('ItemService getCatelogs:');
        return this.http.get<Catalog[]>(this.url + 'report/catelog/findAll');
    }
    getPaymentCategorys(): Observable<PaymentCategory[]> {
//        console.log('ItemService getPaymentCategorys:');
        return this.http.get<PaymentCategory[]>(this.url + 'paymentcategory/findAll');
    }
    getPickDay(): Observable<PickupDay[]> {
//        console.log('ItemService getPickDay:');
        return this.http.get<PickupDay[]>(this.url + 'report/pickupday/findAll');
    }
    getPickTime(): Observable<PickupDay[]> {
//        console.log('ItemService getPickTime:');
        return this.http.get<PickupDay[]>(this.url + 'report/pickuptime/findAll');
    }
    getPaymentlink(key: String): Observable<PaymentLink> {
//        console.log('ItemService getPaymentlink:');
        return this.http.get<PaymentLink>(this.url + 'report/findPaymentLinkKey/' + key);
    }
    getPaymentlinks(): Observable<PaymentLink[]> {
//        console.log('ItemService getPaymentlink:');
        return this.http.get<PaymentLink[]>(this.url + 'paymentLink/findAll');
    }
    validateItem(item: Item): Observable<Item> {
        return this.http.post<Item>(this.url + 'login/validate', item);
    }
    savePaymentlink(paymentlink: PaymentLink): Observable<boolean> {
       console.log('ItemService savePaymentlink: ' + JSON.stringify(paymentlink) + ':' + this.url );
        return this.http.post<boolean>(this.url + 'paymentLink/update', paymentlink);
    }
    savePayment(paymentlink: PaymentLink): Observable<boolean> {
        return this.http.post<boolean>(this.url + 'report/updatePayment', paymentlink);
    }
    saveAveryLabel(averyLabels: AveryLabel[]): Observable<boolean> {
//        console.log('ItemService saveAveryLabel: ' + JSON.stringify(averyLabels) + ':' + this.url );
        return this.http.post<boolean>(this.url + 'report/averylabel', averyLabels);
    }
    saveGrocery(grocery: Grocery): Observable<Grocery> {
//        console.log('ItemService: grocery json: ' + JSON.stringify(grocery));
        return this.http.post<Grocery>(this.url + 'grocery/update', grocery);
    }
    savePurchase(purchase: PurchaseDetail): Observable<PurchaseDetail> {
//        console.log('ItemService: Purchase json: ' + JSON.stringify(purchase));
        return this.http.post<PurchaseDetail>(this.url + 'grocery/updatePurchase', purchase);
    }
    savePaymentCategory(paymentCategory: PaymentCategory): Observable<PaymentCategory> {
//        console.log('ItemService: payment category json: ' + JSON.stringify(paymentCategory));
        return this.http.post<PaymentCategory>(this.url + 'paymentcategory/update', paymentCategory);
    }
    saveOrder(order: Order): Observable<Order> {
//        console.log('ItemService: order json: ' + JSON.stringify(order));
        return this.http.post<Order>(this.url + 'sale/update', order);
    }
    sendEmvOrder(order: Order): Observable<boolean> {
//        console.log('sendEmvOrder: order json: ' + JSON.stringify(order));
        return this.http.post<boolean>(this.printUrl + 'emv/sale', order);
    }
    sendVoidEmvOrder(selectedId: Number, reason: String): Observable<boolean> {
        return this.http.get<boolean>(this.printUrl + 'emv/voidsale/' + selectedId + '/reason/' + reason);
    }
    sendBackup(startDate: String): Observable<boolean> {
//       console.log('sendBackup: string json: ');
        return this.http.get<boolean>(this.url + 'admin/backup/' + startDate);
    }
    printReceipt(printRpt: PrintReceipt): Observable<boolean> {
//        console.log('printReceipt: printer json: ' + JSON.stringify(printRpt) + '   ' + this.printUrl + 'print');
        return this.http.post<boolean>(this.printUrl + 'print', printRpt);
    }
    saveItem(item: Item): Observable<Item> {
//        console.log('ItemService: Item json: ' + JSON.stringify(item));
        return this.http.post<Item>(this.url + 'item/update', item);
    }
    saveShow(selectedId: Number) {
//        console.log('ItemService: saveShow json: ' + this.url + 'item/updateshowId/' + selectedId);
        return this.http.get(this.url + 'item/updateshowId/' + selectedId);
    }

    deleteItem(selectedId: Number) {
        this.http.post(this.url + 'item/delete', selectedId);
    }
    reprocessOrderById(selectedId: Number, typ: String) {
        return this.http.get(this.url + 'report/reprocess/' + selectedId + '/status/' + typ);
    }
    voidSalesById(selectedId: Number, reason: String): Observable<Order> {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Order>(this.url + 'sale/void/' + selectedId + '/reason/' + reason + '/printoption/' + localStorage.getItem('PrintOption'));
    }
    getNotices(): Observable<Notice[]> {
//        console.log('NoticeService getNotices:');
        return this.http.get<Notice[]>(this.url + 'notice/findAll');
    }
    save(notice: Notice) {
        return this.http.post(this.url + 'notice/update', notice);
    }
    deleteNotice(selectedId: Number) {
        this.http.post(this.url + 'notice/delete', selectedId);
    }
    getUsers(): Observable<User[]> {
//        console.log('UserService getUsers:');
        return this.http.get<User[]>(this.url + 'user/findAll');
    }

    getUser(selectedId: String): Observable<User> {
//        console.log('UserService: User json: ' + this.url + 'user/findId/' + selectedId);
        return this.http.get<User>(this.url + 'user/findId/' + selectedId);
    }

    validateLogin(user: LoginRequest): Observable<JwtResponse> {
//        console.log('UserService: User json: ' + JSON.stringify(user));
        return this.http.post<JwtResponse>(this.url + 'login/validate', user);
    }

    saveUser(user: User): Observable<User> {
//        console.log('UserService: User json: ' + JSON.stringify(user));
        return this.http.post<User>(this.url + 'user/update', user);
    }
    deleteUser(selectedId: Number) {
        this.http.get(this.url + 'user/delete/' + selectedId);
    }
    getSummaryAll(): Observable<SaleSummary[]> {
//        console.log('ReportService getItemSummaryAll:');
        return this.http.get<SaleSummary[]>(this.url + 'report/summaryall');
    }
    getSummary(salefilter: SaleFilter): Observable<SaleSummary[]> {
//        console.log('ReportService getItemSummary:');
        return this.http.post<SaleSummary[]>(this.url + 'report/summary', salefilter);
    }
    getSalesRange(startDate: String, endDate: String): Observable<SaleSummary[]> {
//        console.log('ReportService getItemSummary:');
        return this.http.post<SaleSummary[]>(this.url + 'admin/report/range/start/' + startDate + '/end/' + endDate, null);
    }
    getDeliveryAll(): Observable<OrderDisplay[]> {
//        console.log('ReportService getDeliveryAll:');
        return this.http.get<OrderDisplay[]>(this.url + 'report/deliveryall/');
    }
    getDelivery(salefilter: SaleFilter): Observable<OrderDisplay[]> {
//        console.log('ReportService getDelivery:');
        return this.http.post<OrderDisplay[]>(this.url + 'report/delivery/', salefilter);
    }
    getDosa(): Observable<SaleDetails[]> {
        return this.http.get<SaleDetails[]>(this.url + 'report/dosa/');
    }
    getDetails(salefilter: SaleFilter): Observable<SaleDetails[]> {
        return this.http.post<SaleDetails[]>(this.url + 'report/details/', salefilter);
    }
    saveSaleItem(saleItemId: number, typ: String) {
        return this.http.post(this.url + 'report/update/saleItemId/' + saleItemId + '/typ/' + typ, null);
    }
    reprocessSale(saleId: number, typ: String) {
        return this.http.get(this.url + 'report/reprocess/' + saleId + '/status/' + typ);
    }
}
