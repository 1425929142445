import { Component, OnInit, OnDestroy } from '@angular/core';
import { ItemService } from 'src/app/services/item.service';
import { AveryLabel } from 'src/app/models/averylabel';

@Component({
  selector: 'app-averylabel',
  templateUrl: './averylabel.component.html',
  styleUrls: ['./report.component.css']
})
export class AveryLabelComponent implements OnInit, OnDestroy {
  averyLabels: AveryLabel[] = [];
  label: string;
  subLabel: string;
  errorMsg: string;
  count = 1;
  constructor(private srv: ItemService) {
  }

  ngOnInit() {
  }
  ngOnDestroy() {
  }
  reset() {
    this.averyLabels = [];
    this.label = null;
    this.subLabel = null;
    this.count = 0;
  }
  deleteName(label: string) {
    if (this.averyLabels.length < 1) {
      return;
    }
    const labels: AveryLabel[] = [];
    this.averyLabels.forEach(obj => {
      if (obj.label.toUpperCase() !== label) {
        labels.push(obj);
      }
    });
    this.averyLabels = labels;
    this.count = this.averyLabels.length;
    console.log('deleteName: ' + this.label + ': ' + this.subLabel + ' ' + this.count);
  }
  repeat(): void {
    if (this.label == null || this.label.trim() === '' || this.count === 10) {
      return;
    }
    const averyLabel = new AveryLabel();
    averyLabel.label = this.label.toUpperCase();
    averyLabel.subLabel = this.subLabel != null ? this.subLabel.toUpperCase() : null;
    for (let i = this.count; i < 10; i++) {
      this.averyLabels.push(averyLabel);
    }
    this.count = this.averyLabels.length;
  }
  addList(): void {
    if (this.label == null || this.label.trim() === '' || this.count === 10) {
      return;
    }
    console.log('AddList: ' + this.label + ': ' + this.subLabel + ' ' + this.count);
    const averyLabel = new AveryLabel();
    averyLabel.label = this.label.toUpperCase();
    averyLabel.subLabel = this.subLabel != null ? this.subLabel.toUpperCase() : null;
    this.averyLabels.push(averyLabel);
    this.label = null;
    this.subLabel = null;
    this.count = this.averyLabels.length;
  }
  onSave(): void {
    if (this.label === null && this.averyLabels.length < 1) {
      return;
    }
    if (this.label != null) {
      const averyLabel = new AveryLabel();
      averyLabel.label = this.label.toUpperCase();
      averyLabel.subLabel = this.subLabel != null ? this.subLabel.toUpperCase() : null;
      this.averyLabels.push(averyLabel);
      this.label = null;
      this.subLabel = null;
      this.count = 0;
    }
    this.srv.saveAveryLabel(this.averyLabels)
      .subscribe((resp: Boolean) => {
         this.averyLabels = [];
         window.open('/images/Avery5163SPrint.docx');
      },
        error => {
          this.errorMsg = 'Not able to print';
          //  console.log('Error0 :: ' + this.errorMsg + ' : ' + error);
        }
      );
  }
}
