import { Sales } from './sales';

export class PrintReceipt {
    printCount: number;
    printerName: String;
    printerOption: String = 'L';
    message: String;
    sales: Array<Sales>;
    salesAmount: any;
    id: number;
    createdDate: Date;
    voidReason: String;
    constructor(printCount?: number, printerName?: String, printerOption?: String, message?: String, 
    sales?: Array<Sales>, salesAmount?: any, id?: number, createdDate?: Date, voidReason?: String) { }
}
