import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../models/user';
import { ItemService } from 'src/app/services/item.service';

@Component({

    selector: 'app-edituser',
    templateUrl: './edit.component.html',
    styleUrls: ['./user.component.css']

})

export class EdituserComponent implements OnInit {

    user: User;
    errorMsg: string;

    constructor(private router: Router, private route: ActivatedRoute, private usrSrv: ItemService) {
        console.log('EdituserComponent: constructor 1');
        this.user = new User();
        this.user.emailAddress = null;
        this.user.password = null;
         if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }
    }

    ngOnInit() {

        const selectedId = <number><any>this.route.snapshot.paramMap.get('id');
        const userObj = JSON.parse(sessionStorage.getItem('users'));
        console.log('EdituserComponent: ngOnInit 1:' + selectedId + '--');
        if (selectedId > 0) {
            if (userObj != null) {
                for (let i = 0; i < userObj.length; i++) {
                    console.log('EdituserComponent: ngOnInit :' + selectedId + '--'+userObj[i].userId);
                    if (userObj[i].userId == selectedId) {
                        this.user = userObj[i];
                        console.log('EdituserComponent: user ' + this.user.lastName + "      " + this.user.status);
                    }
                }
            }
        }
    }

    onSave(): void {
        this.usrSrv.saveUser(this.user)
            .subscribe((resp: User) => {
                sessionStorage.setItem('users', null);
                this.router.navigateByUrl('/user');
            },
                error => {
                    this.errorMsg = 'Invalid onSave';
                    console.log('Error :: ' + this.errorMsg);
                }
            );
    }
    onDelete(): void {

        console.log('EdituserComponent: onLogin');

        this.usrSrv.deleteUser(Number(this.user.userId));
        this.router.navigateByUrl('/user');
    }
    setStatusButton(str): void {
        this.user.status = str;
        console.log("setStatusButton :: " + this.user.status);
    }
}
