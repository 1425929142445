import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SaleSummary } from '../../models/salesummary';
import { ItemService } from 'src/app/services/item.service';
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';

@Component({
  selector: 'app-summaryall',
  templateUrl: './summary.component.html',
  styleUrls: ['./report.component.css']
})
export class SummaryAllComponent implements OnInit, OnDestroy {

  interval: any;
  saleSummary: SaleSummary[];
  salesItemIds: number[] = [];

  webSocketAPI: WebSocketAPI;
  stompClient: any;
  topic: string = '/topic/summary';

  constructor(private router: Router, private srv: ItemService) {
  }

  ngOnInit() {
    this.webSocketAPI = new WebSocketAPI();
    this.reWebSocketConnect();
    this.interval = setInterval(() => {
      this.reWebSocketConnect();
    }, 600000);
  }
  reWebSocketConnect() {
    this.stompClient = this.webSocketAPI.connect();
    const _this = this;
    _this.stompClient.connect({}, function (frame) {
      _this.stompClient.subscribe(_this.topic, function (sdkEvent) {
        if (sdkEvent != null) {
          _this.saleSummary = JSON.parse(sdkEvent.body);
        } else {
          _this.saleSummary = [];
        }
      });
    }, _this.stompClient.errorCallBack);
  }
  ngOnDestroy(): void {
    if (this.interval) {
        clearInterval(this.interval);
    }
  }
  addList(saleItemId: number): void {
    this.salesItemIds.push(saleItemId);
  }
  onSave(saleItemId: number, typ: String): void {
    this.srv.saveSaleItem(saleItemId, typ);
  }
  getSaleSummaryRefresh() {
    this.srv.getSummaryAll()
      .subscribe((resp: SaleSummary[]) => {
        this.saleSummary = resp;
      },
        error => console.log('Error :: ' + error)
      );
  }
}
