import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./report.component.css']
})
export class BackupComponent implements OnInit {

  startDate: String;
  errorMsg: String;
  isDone: boolean;
  constructor(private router: Router, private itemSrv: ItemService) {
    console.log('BackupComponent: constructor');
  }
  ngOnInit() {
    this.startDate = null;
    this.isDone = false;
  }
  onSave(): void {
    console.log('BackupComponent: ' + this.startDate);
    this.itemSrv.sendBackup(this.startDate)
      .subscribe((resp: Boolean) => {
        this.isDone = true;
      },
        error => {
          this.errorMsg = 'Not able to take backup';
          //  console.log('Error0 :: ' + this.errorMsg + ' : ' + error);
        }
      );
  }
}
