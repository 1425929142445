import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notice } from '../../models/notice';
import { DomSanitizer } from '@angular/platform-browser';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {

  notices: Notice[];
  notice: Notice;
  error: string;
  thumbnail: any;
  constructor(private router: Router, private srv: ItemService, private sanitizer: DomSanitizer) {
    console.log('NoticeComponent: constructor ');
    this.getNotices();
  }

  ngOnInit() {
    console.log('NoticeComponent: ');
  }
  onSave(): void {
    this.srv.save(this.notice)
      .subscribe(() => {
        this.router.navigateByUrl('/home');
      },
        error => {
          this.error = 'Invalid onSave';
          console.log('Error :: ' + this.error);
        }
      );
  }
  onDelete(): void {
    this.srv.deleteNotice(this.notice.id);
  }
  getNotices(): void {
    console.log('NoticeComponent: getNotices');
    this.srv.getNotices()
      .subscribe((resp: Notice[]) => {
        this.notices = resp;
        resp.forEach(element => {
          this.getByteByString(element.imageObj);
          if (element.status === 'A') {
            this.notice = element;
          }
        });
      },
        error => console.log('Error :: ' + error)
      );
  }
  setStatusButton(id): void {
    this.notices.forEach(element => {
      if (element.id === id) {
        this.notice = element;
        this.notice.status = 'A';
      }
    });
  }
   onFileChanged(event) {
        const files = event.target.files;
        const file = files[0];

        if (files && file) {
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;
            const max_size = 20971520;
            if (file.size > max_size) {
                this.error = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
                return false;
            }
            const reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
            this.getByteByString(this.notice.imageObj) ;
        }
    }
    _handleReaderLoaded(readerEvt) {
        const binaryString = readerEvt.target.result;
        this.notice.imageObj = btoa(binaryString);  // Converting binary string data.
    }
    getByteByString(data) {
        this.thumbnail = null;
        if (data != null) {
            const blob = 'data:image/jpeg;base64,' + data;
            this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(blob);
        }
    }

}
