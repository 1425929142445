import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SaleSummary } from '../../models/salesummary';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./report.component.css']
})
export class DateRangeComponent implements OnInit {

  saleSummary: SaleSummary[];
  errorMsg: String;
  rowData: any;
  columnDefs = [
    { headerName: 'Item Name', field: 'itemName', sortable: true, filter: true },
    { headerName: 'Quantity', field: 'quantity', sortable: true },
    { headerName: 'Amount', field: 'totalAmt', sortable: true }
  ];
  constructor(private router: Router, private srv: ItemService, private location: Location) {
    if (sessionStorage.getItem('role') == null) {
      this.router.navigate(['/login']);
    }

  }
  startDate: String;
  endDate: String;
  totalAmount: number;
  ngOnInit() {
    this.totalAmount = 0.00;
    this.startDate = null;
    this.endDate = null;
  }
  pageRefresh() {
    location.reload();
  }
  getSaleSummary() {
    this.errorMsg = '';
    let stDate;
    let edDate;
    if (this.startDate == null && this.endDate == null) {
      this.errorMsg = 'Please enter Start or End date';
      return;
    }
    if (this.startDate != null && this.startDate.length > 7 && this.endDate != null && this.endDate.length > 7) {
      stDate = this.startDate;
      edDate = this.endDate;
    } else if (this.startDate != null && this.startDate.length > 7) {
      stDate = this.startDate;
      edDate = this.startDate;
    } else if (this.endDate != null && this.endDate.length > 7) {
      stDate = this.endDate;
      edDate = this.endDate;
    } else {
      return null;
    }
    this.srv.getSalesRange(stDate, edDate)
      .subscribe((resp: SaleSummary[]) => {
        this.saleSummary = resp;
        this.totalAmount = 0.00;
        this.rowData = resp;
        for (const element of resp) {
          this.totalAmount += (element.totalAmt * 100) / 100;
        }
        this.totalAmount = (this.totalAmount * 100) / 100;
      },
        error => console.log('Error :: ' + error)
      );

  }
}
