import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse, JwtResponse } from '../../models/loginresponse';
import { LoginRequest } from '../../models/loginrequest';
import { ItemService } from 'src/app/services/item.service';

declare var getClaimsFromToken: any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginRequest: LoginRequest = new LoginRequest();
  loginResponse: LoginResponse;
  errorMsg: string;
  title = 'SV Lotus Temple';
  constructor(private router: Router, private usrSrv: ItemService) {
    console.log('LoginComponent: constructor');
    this.loginRequest = new LoginRequest();
  }

  ngOnInit(): void {
    console.log('LoginComponent: constructor');
  }

  onLogin(): void {
    this.usrSrv.validateLogin(this.loginRequest)
      .subscribe((resp: JwtResponse) => {
        this.loginResponse = getClaimsFromToken(resp.accessToken);
        this.router.navigateByUrl('/home');
        sessionStorage.setItem('accessToken', '' + resp.accessToken);
        console.log('Role: ' + this.loginResponse.role.substr(5).toLowerCase());
        sessionStorage.setItem('role',  this.loginResponse.role.substr(5).toLowerCase());
      },
        error => {
          this.errorMsg = 'Invalid Login';
          console.log('Error :: ' + this.errorMsg);
        }

      );
  }

}
