import { Component, OnInit } from '@angular/core';
import { PaymentCategory } from '../../models/paymentcategory';
import { ItemService } from '../../services/item.service';

@Component({
  selector: 'app-paymentcategory',
  templateUrl: './paymentcategory.component.html',
  styleUrls: ['./paymentcategory.component.css']
})
export class PaymentCategoryComponent implements OnInit {

  categorys: PaymentCategory[];

  constructor(private itemSrv: ItemService) {
    console.log('PaymentcategoryComponent: constructor: ' );
  }

  ngOnInit() {
    this.getPaymentCategory();
  }
  getPaymentCategory() {
    this.itemSrv.getPaymentCategorys()
      .subscribe((resp: PaymentCategory[]) => {
        this.categorys = resp;
        sessionStorage.setItem('paymentCategory', JSON.stringify(resp));
        },
        error => console.log('Error :: ' + error)
      );
  }
}
