export class PaymentCategory {

    id: number;
    categoryName: String;
    description: String;
    status: String;
    createdDate: Date;
    updatedDate: Date;
    constructor(id?: number, categoryName?: string, description?: string, status?: string, createdDate?: Date, updatedDate?: Date) { }
}
