import { Component, OnInit, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Order } from '../../models/order';
import { Sales } from '../../models/sales';
import { PrintReceipt } from '../../models/printreceipt';
import { Router } from '@angular/router';
import { RunnerComponent } from '../runner.component';
import { PaymentCategory } from 'src/app/models/paymentcategory';

declare function setSaleElementEnabled(): any;

@Component({
    selector: 'app-generalsales',
    templateUrl: './generalsales.conponent.html',
    styleUrls: ['./order.component.css']
})

export class GeneralSalesComponent extends RunnerComponent implements OnInit, OnDestroy {
    order: Order;
    cardNumber: string;
    cardName: string;
    expiration: string;
    name: string;
    printRpt: PrintReceipt;
    email: string;
    category: string;
    amount: any;
    paymentCategoryObj: PaymentCategory[];
    creditCardOption: string;
    errorMsg: string;
    orderLite: Order;
    sales: Sales;
    createdDate: string;
    paymentCategory: PaymentCategory;
    constructor(private router: Router, private itemSrv: ItemService) {
        super('From GeneralSalesComponent', 'ORDER', '');
        console.log('GeneralSalesComponent: constructor ');
        this.paymentCategoryObj = JSON.parse(sessionStorage.getItem('paymentCategory'));
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }
    }
    ngOnInit() {
        console.log('GeneralSalesComponent: ngOnInit ');
        if (this.paymentCategoryObj == null) {
            this.getPaymentCategory();
        }
        this.printRpt = new PrintReceipt();
        this.printRpt.printerOption = 'L';
        this.printRpt.printCount = Number(localStorage.getItem('PrintCount'));
        this.printRpt.printerName = localStorage.getItem('PrinterName');
        this.creditCardOption = localStorage.getItem('creditCardOption');
    }
    ngOnDestroy() {
        console.log('GeneralSalesComponent: ngOnDestroy');
    }
    getPaymentCategory() {
        this.itemSrv.getPaymentCategorys()
            .subscribe((resp: PaymentCategory[]) => {
                this.paymentCategoryObj = resp;
                sessionStorage.setItem('paymentCategory', JSON.stringify(resp));
            },
                error => console.log('Error :: ' + error)
            );
    }
    selectPaymentCategory(event: any) {
        this.paymentCategoryObj.forEach(element => {
            if (element.id == event.value) {
                this.paymentCategory = element;
            }
        });
//        console.log('PaymentCategory :: ' + this.paymentCategory.id + ' ' + this.paymentCategory.categoryName);
    }
    onSaveEmv(modal): void {
        if (this.validate()) {
            return;
        }
        this.setData();
        modal.show();
        this.itemSrv.sendEmvOrder(this.order)
            .subscribe((resp: boolean) => {
                modal.hide();
                this.resetModel();
            },
                error => {
                    this.errorMsg = 'Not able to process payment... please retry';
                    //  console.log('Error0 :: ' + this.errorMsg + ' : ' + error);
                }
            );
    }
    onSave(): void {
        this.order.creditCard = (<HTMLInputElement>document.getElementById('cardNumber')).value;
        this.order.expiryDate = (<HTMLInputElement>document.getElementById('expiration')).value;
        this.order.nameOnCard = (<HTMLInputElement>document.getElementById('cardName')).value;
        this.order.salesAmount = this.amount;
        console.log('1.total count: ' + this.order.creditCard + ' : ' + this.order.creditCard.trim.length);
        console.log('2.total count: ' + this.order.expiryDate);
        console.log('3.total count: ' + this.order.nameOnCard);
        console.log('4.total count: ' + this.order.salesAmount);
        if (this.validate()) {
            return;
        }
        if (this.order.creditCard == null || this.order.creditCard.length < 8) {
            this.errorMsg = 'Invalid credit card number';
            return;
        }
        if (this.order.nameOnCard == null || this.order.nameOnCard.length < 4) {
            this.errorMsg = 'Invalid name';
            return;
        }
        if (this.order.expiryDate == null || this.order.expiryDate.length < 4) {
            this.errorMsg = 'Invalid expiry date';
            return;
        }
        console.log('5.total count: ' + this.order.paymentCategoryId);
        this.setData();
        this.order.sales.push(this.sales);

        this.itemSrv.saveOrder(this.order)
            .subscribe((resp: Order) => {
                this.errorMsg = 'Successful processed';
                if (this.printRpt.printerOption === 'L') {
                    this.orderLite = resp;
                    this.printReceipt();
                }
            },
                error => {
                    this.errorMsg = 'Not able to process payment ';
                    console.log('Error0 :: ' + this.errorMsg + ' : ' + error);
                }
            );
        this.resetModel();
    }
    onSaveCash(): void {
        if (this.validate()) {
            return;
        }
        this.setData();
        this.order.salesType = 'K';
        this.itemSrv.saveOrder(this.order)
            .subscribe((resp: Order) => {
                this.errorMsg = 'Successful processed';
                if (this.printRpt.printerOption === 'L') {
                    this.orderLite = resp;
                    this.printReceipt();
                }
            },
                error => {
                    this.errorMsg = 'Not able to process payment ';
                    console.log('Error0 :: ' + this.errorMsg + ' : ' + error);
                }
            );
        this.resetModel();
    }
    setData() {
        this.order = new Order();
        this.order.email = this.email;
        this.order.salesType = 'C';
        this.order.paymentCategoryId = this.paymentCategory.id;
        this.order.createdDate = new Date(this.createdDate);
        this.order.salesAmount = this.amount;
        this.order.printerOption = 'L';
        this.order.printerName = this.printRpt.printerName;
        this.order.applicationType = 'C';

        this.sales = new Sales();
        this.sales.itemId = this.paymentCategory.id;
        this.sales.price = this.order.salesAmount;
        this.sales.itemName = this.paymentCategory.categoryName;
        this.sales.createdDate = this.createdDate;
        this.sales.togo = 'Y';
        this.sales.quantity = 1;
        this.order.sales = [];
        this.order.sales.push(this.sales);
    }
    validate(): boolean {
        if (this.createdDate == null || this.createdDate.length < 8) {
            this.errorMsg = 'Please select date';
            return true;
        }
        if (this.amount == null || this.amount < 1) {
            this.errorMsg = 'Invalid amount';
            return true;
        }
        if (this.amount.indexOf('.') < 1) {
            this.amount = (Math.round(this.amount * 100) / 100).toFixed(2);

        }
        console.log('EV :: ' + this.amount);

        if (this.email == null || this.email.length < 2 || this.email.indexOf('@') === -1) {
            this.errorMsg = 'Invalid email';
            return true;
        }

        if (this.paymentCategory == null || this.paymentCategory.id <= 0) {
            this.errorMsg = 'Select Payment type';
            return true;
        }
        this.errorMsg = null;
        return false;
    }

    printReceipt(): void {
        this.printRpt.id = this.orderLite.id;
        this.printRpt.createdDate = this.orderLite.createdDate;
        this.printRpt.salesAmount = this.order.salesAmount;
        this.printRpt.sales = this.order.sales;
        this.itemSrv.printReceipt(this.printRpt).subscribe();
        this.resetModel();
    }
    resetModel() {
        this.order = null;
        this.cardNumber = null;
        this.cardName = null;
        this.expiration = null;
        this.name = null;
        this.email = null;
        this.amount = 0.00;
        this.errorMsg = null;
        this.orderLite = null;
        this.paymentCategory = null;
        this.createdDate = null;
    }
}
