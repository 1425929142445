import { environment } from 'src/environments/environment';
// Declare SockJS and Stomp
declare var SockJS;
declare var Stomp;
export class WebSocketAPI {
    webSocketEndPoint: string = environment.url + 'wss';
    stompClient: any;
    constructor() {
    }
    public connect() { 
        const socket = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(socket);
        return this.stompClient; 
    } 

    public disconnect() {
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
        console.log('Disconnected');
    }
    // on error schedule a reconnection attempt
    public errorCallBack(error) {
      console.log('errorCallback -> ' + error)
      setTimeout(() => {
       this.connect();
       }, 5000);
    }

}