import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-orderbydate',
  templateUrl: './orderbydate.component.html',
  styleUrls: ['./report.component.css']
})
export class OrderByDateComponent implements OnInit {

  startDate: string;
  errorMsg: String;
  constructor(private router: Router, private itemSrv: ItemService) {
    console.log('OrderByDateComponent: constructor');
  }
  ngOnInit() {
    this.startDate = null;
  }
  getSaleHistoryReport(): void {
    console.log('OrderByDateComponent: ' + this.startDate);
    sessionStorage.setItem('orderHistoryStartDate', this.startDate);
    this.router.navigateByUrl('/reports/orderhistory');
  }
}
