import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { OrderDisplay } from '../../models/orderdisplay';
import { SaleFilter } from '../../models/salefilter';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./report.component.css']
})

export class DeliveryComponent implements OnInit, OnDestroy, AfterViewChecked {

  orderDisplay: OrderDisplay[];
  orderDisplay1: OrderDisplay[];
  orderDisplay2: OrderDisplay[];
  orderDisplay3: OrderDisplay[];
  orderdisplay: OrderDisplay;
  salefilter: SaleFilter;
  interval: any;
  rowSize: number;
  rowIncrease: number;
  dataStr: String;
  audio: any;
  audios: any[];
  timeCount: number;
  constructor(private router: Router, private srv: ItemService, private location: Location) {
    this.audio = new Audio();
  }

  ngOnInit() {
    this.salefilter = JSON.parse(sessionStorage.getItem('salefilter'));
    this.audios = JSON.parse(localStorage.getItem('audio'));
    this.timeCount = 0;
    this.rowSize = 3;
    this.rowIncrease = 0;
    this.getDelivery(this.salefilter);
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.getTimerRefresh();
    }, 60000);
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  ngAfterViewChecked() {
    this.getEnable();
  }
  customerNumber(saleItemId: number): String {
    return saleItemId.toString().substring(saleItemId.toString().length - 2);
  }
  customerName(customerName: string): String {
    return customerName.length > 30 ? customerName.substring(0, 30) : customerName.padEnd(30);
  }
  pageRefresh() {
    location.reload();
  }
  onSave(saleItemId: number, event): void {
    if (event.target.checked) {
      (<HTMLInputElement>document.getElementById('cus' + saleItemId)).setAttribute("class", "strickthough");
      (<HTMLInputElement>document.getElementById('qty' + saleItemId)).setAttribute("class", "strickthough");
    } else {
      (<HTMLInputElement>document.getElementById('cus' + saleItemId)).setAttribute("class", "nonstrickthough");
      (<HTMLInputElement>document.getElementById('qty' + saleItemId)).setAttribute("class", "nonstrickthough");
    }
    this.srv.saveSaleItem(saleItemId, event.target.checked ? 'Y' : 'N').subscribe();
    if (this.orderDisplay != null) {
      for (const order of this.orderDisplay) {
        for (const entry of order.items) {
          if (entry.id === saleItemId) {
            entry.deliveryStatus = event.target.checked ? 'Y' : 'N';
          }
        }
      }
    }
  }
  reprocessSale(saleId: number, typ: String): void {
    this.srv.reprocessSale(saleId, typ).subscribe();
  }
  getDeliveryRefresh() {
    this.getDelivery(this.salefilter);
  }
  playAudio(saleItemId: number) {
    this.audio.src = './assets/audio/' + this.customerNumber(saleItemId) + '.mp3';
    //    this.audio = this.getDimensions(Number(this.customerNumber(saleItemId)));
    this.audio.load();
    this.audio.play();
  }
  getDimensions(id) {
    for (let index = 0; index < this.audios.length; index++) {
      if (id === index) {
        return this.audios[index];
      }
    }
  }
  getDelivery(salefilter: SaleFilter) {
    this.orderDisplay1 = [];
    this.orderDisplay2 = [];
    this.orderDisplay3 = [];
    this.srv.getDelivery(salefilter)
      .subscribe((resp: OrderDisplay[]) => {
        this.orderDisplay = resp;
        this.interval = 0;
        this.rowIncrease = 1;
        if (resp != null) {
          for (let i = 0; i < resp.length; i++) {
            this.orderdisplay = resp[i];
            if (this.orderdisplay.appType === 'K') {
              this.orderdisplay.appType = 'Online (' + this.orderdisplay.pickupTime + ')';
            } else {
              this.orderdisplay.appType = '';
            }
            if (this.rowIncrease == 1) {
              this.orderDisplay1.push(resp[i]);
            } else if (this.rowIncrease == 2) {
              this.orderDisplay2.push(resp[i]);
            } else {
              this.orderDisplay3.push(resp[i]);
            }
            if (this.rowIncrease > 2) {
              this.rowIncrease = 0;
            }
            this.rowIncrease += 1;
          }
        }
      },
        error => console.log('Error :: ' + error)
      );
    //    const beforeReload = $(window).bind("load", function(){
    //         this.getEnable();
    //    });
    //    console.log('DeliveryComponent: a '+ beforeReload);
  }
  getEnable() {
    if (this.orderDisplay != null) {
      for (const order of this.orderDisplay) {
        for (const entry of order.items) {
          const ent1 = <HTMLInputElement>document.getElementById('cus' + entry.id);
          if (entry.deliveryStatus === 'Y' && ent1 != null) {
            (<HTMLInputElement>document.getElementById('cus' + entry.id)).setAttribute("class", "strickthough");
            (<HTMLInputElement>document.getElementById('qty' + entry.id)).setAttribute("class", "strickthough");
          }
        }
      }
    }
  }
  getTimerRefresh() {
    if (this.timeCount > 1) {
      this.getDeliveryRefresh();
    }
    this.timeCount++;
  }
}
