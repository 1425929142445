import { Component } from '@angular/core';

@Component({

  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  audios: any[] = [];
  audio: any;
  title = 'Sri Venkateswara Lotus Temple';
  constructor() {
    console.log('DeliveryComponent: constructor ');
    for (let i = 0; i < 10; i++) {
      this.audio = new Audio();
      this.audio.src = './assets/audio/' + (i < 10 ? '0' + i : i) + '.mp3';
      this.audio.load();
      this.audios.push(this.audio);
    }
  }

}
