import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from '../../services/item.service';
import { PickupDay } from 'src/app/models/pickupday';
import { PaymentLink } from 'src/app/models/paymentlink';
import { formatDate } from '@angular/common';

@Component({

    selector: 'app-editpaymentlink',
    templateUrl: './edit.component.html',
    styleUrls: ['./paymentlink.component.css']

})

export class EditPaymentLinkComponent implements OnInit {

    errorMsg: string;
    id: number;
    orderId: number;
    salesId: number;
    key: String;
    message: String;
    amount: any;
    email: String;
    pickupDay: string;
    pickupTime: String;
    pickupTimes: PickupDay[];
    link: PaymentLink;
    status: String;

    constructor(private router: Router, private route: ActivatedRoute, private itemSrv: ItemService) {
        console.log('EditPaymentLinkComponent: constructor');
    }

    ngOnInit() {

        const selectedId = this.route.snapshot.paramMap.get('id');
        this.getPickupTime();
        if (selectedId !== '0') {
            this.itemSrv.getPaymentlink(selectedId)
                .subscribe((resp: PaymentLink) => {
                    this.link = resp;
                    this.amount = this.link.amount;
                    this.email = this.link.email;
                    this.salesId = this.link.salesId;
                    this.orderId = this.link.orderId;
                    this.message = this.link.message;
                    this.pickupDay = this.link.pickupDay;
                    this.pickupTime = this.link.pickupTime;
                    this.status = this.link.status;
                    const d = this.link.pickupDay;
                    this.pickupDay = d.substring(0, 4) + '-' + d.substring(4, 6) + '-' + d.substring(6, 8);
                },
                    error => {
                        this.errorMsg = 'Invalid ngOnInit';
                        console.log('Error :: ' + this.errorMsg);
                    }
                );
        } else {
            this.link = new PaymentLink();
        }
    }

    onSave(): void {

        this.errorMsg = null;
        if (this.pickupDay == null) {
            this.errorMsg = 'Please select pick day';
            return;
        }
        if (this.pickupTime == null) {
            this.errorMsg = 'Please select pick time';
            return;
        }
        if (this.message == null) {
            this.errorMsg = 'Please enter the message';
            return;
        }
        if (this.email == null || this.email.length < 2 || this.email.indexOf('@') === -1) {
            this.errorMsg = 'Invalid email';
            return;
        }
        if (this.amount == null || this.amount <= 0) {
            this.errorMsg = 'Enter amount';
            return;
        }
        const d = this.pickupDay;
        const formattedDt = formatDate(d, 'yyyyMMdd', 'en_US');
        console.log('---' + formattedDt);
        this.link.amount = this.amount.replaceAll(",","").replaceAll("$","");
        this.link.email = this.email;
        this.link.salesId = this.salesId;
        this.link.orderId = this.orderId;
        this.link.message = this.message;
        this.link.pickupDay = formattedDt;
        this.link.pickupTime = this.pickupTime;

        this.itemSrv.savePaymentlink(this.link)
            .subscribe(() => {
                this.router.navigateByUrl('/paymentlink');
            },
                error => {
                    this.errorMsg = 'Invalid onSave';
                    console.log('Error :: ' + this.errorMsg);
                }
            );
    }
    getPickupTime() {
        this.itemSrv.getPickTime()
            .subscribe((resp: PickupDay[]) => {
                this.pickupTimes = resp;
            },
                error => console.log('Error :: ' + error)
            );
    }
    setPickupTime(value: string) {
        this.pickupTime = value;
        console.log('setPickupTime: ' + value);
    }
}
