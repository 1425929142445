import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentLink } from 'src/app/models/paymentlink';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-paymentlink',
  templateUrl: './paymentlink.component.html',
  styleUrls: ['./paymentlink.component.css']
})
export class PaymentLinkComponent implements OnInit {

  links: PaymentLink[];
  constructor(private router: Router, private itemSrv: ItemService) { }

  ngOnInit() {
    this.getPaymentlinks();
  }
  getPaymentlinks() {
    this.itemSrv.getPaymentlinks()
      .subscribe((resp: PaymentLink[]) => {
        this.links = resp;
      },
        error => console.log('Error :: ' + error)
      );
  }
}
