import { Sales } from './sales';

export class Order {
    id: number;
    nameOnCard: String;
    creditCard: String;
    expiryDate: String;
    sales: Array<Sales>;
    salesAmount: any;
    createdDate: Date;
    printerName: String;
    printerOption: String;
    salesType: String;
    applicationType: String;
    email: String;
    pickupDay: String;
    pickupTime: String;
    paymentCategoryId: number;
    constructor(id?: number, nameOnCard?: string, creditCard?: string, expiryDate?: string, items?: Array<Sales>,
    salesAmount?: any, createdDate?: Date, printerName?: String, printerOption?: String, email?: String
    , pickupDay?: String, pickupTime?: String, salesType?: String, applicationType?: String, paymentCategoryId?: number) { }
}
