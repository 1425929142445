import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseSummary } from 'src/app/models/purchasesummary';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit {

  summary: PurchaseSummary[];
  error: string;
  constructor(private router: Router, private usrSrv: ItemService) {
    console.log('PurchaseComponent: constructor');
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }
  }

  ngOnInit(): void {
    let dNow = new Date();
    let expiry = sessionStorage.getItem('expiry');
    if (Number(expiry) <= (Number(dNow.getMilliseconds) + 3600)) {
      this.router.navigateByUrl('/home');
    }
    this.getPurchases();
  }
  convertStatus(status): String {
    console.log('PurchaseComponent: convertStatus ' + status);
    return status == 'A' ? 'Active' : 'Inactive';
  }
  getPurchases(): void {
    console.log('PurchaseComponent: getUsers');
    this.usrSrv.getPurchases()
      .subscribe((resp: PurchaseSummary[]) => {
          this.summary = resp;
          sessionStorage.setItem('summary', JSON.stringify(resp));
        },
        error => console.log('Error :: ' + error)
      )
  }
}
