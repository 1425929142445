import { Component, OnInit } from '@angular/core';
import { Catalog } from '../../models/catalog';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {

  catalog: Catalog[];
  error: string;

  constructor() {
    console.log('CatalogComponent: constructor: ' );
  }

  ngOnInit() {
  }

}
