import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SaleSummary } from '../../models/salesummary';
import { SaleFilter } from '../../models/salefilter';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./report.component.css']
})
export class SummaryComponent implements OnInit, OnDestroy {

  interval: any;
  saleSummary: SaleSummary[];
  salesItemIds: number[] = [];
  salefilter: SaleFilter;
  constructor(private router: Router, private srv: ItemService, private location: Location) {
  }

  ngOnInit() {
    this.salefilter = JSON.parse(sessionStorage.getItem('salefilter'));
    this.getSaleSummary(this.salefilter);
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.getSaleSummary(this.salefilter);
    }, 5000);
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  pageRefresh() {
    location.reload();
  }
  addList(saleItemId: number): void {
    this.salesItemIds.push(saleItemId);
  }
  onSave(saleItemId: number, typ: String): void {
    this.srv.saveSaleItem(saleItemId, typ);
  }
  getSaleSummaryRefresh() {
    this.getSaleSummary(this.salefilter);
  }
  getSaleSummary(salefilter: SaleFilter) {
    this.srv.getSummary(salefilter)
      .subscribe((resp: SaleSummary[]) => {
        this.saleSummary = resp;
      },
        error => console.log('Error :: ' + error)
      );
  }
}
