export class PaymentLink {
    id: number;
    orderId: number;
    salesId: number;
    key: String;
    message: String;
    amount: any;
    email: String;
    status: String;
    pickupDay: string;
    pickupTime: String;
    nameOnCard: String;
    creditCard: String;
    expiryDate: String;
    ccv: String;
}
