import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ItemService } from '../../services/item.service';
import { SalesHistory } from '../../models/saleshistory';
import { Sales } from '../../models/sales';
import { PrintReceipt } from 'src/app/models/printreceipt';
import { OrderLite } from 'src/app/models/orderlite';
import { Order } from '../../models/order';

@Component({
  selector: 'app-orderhistory',
  templateUrl: './orderhistory.component.html',
  styleUrls: ['./report.component.css']
})
export class OrderHistoryComponent implements OnInit {

  sales: Sales[];
  salehistory: SalesHistory[];
  invoice: number;
  invoiceDate: string;
  totalAmount: number;
  voidAmount: number;
  voidSalesId: number;
  salesType: string;
  reason: string;
  errorMsg: string;
  printRpt: PrintReceipt;
  orderLite: OrderLite;
  role: String;
  startDate: String;

  constructor(private router: Router, private srv: ItemService, private location: Location) {
    if (sessionStorage.getItem('role') == null) {
      this.router.navigate(['/login']);
    }
    this.startDate = sessionStorage.getItem('orderHistoryStartDate');
    this.pageRefresh();
  }

  ngOnInit() {
    this.totalAmount = 0;
    this.voidAmount = 0;
    this.voidSalesId = 0;
    this.reason = '';
    this.salesType = '';
    this.printRpt = new PrintReceipt();
    this.printRpt.printerOption = 'L';
    this.printRpt.printCount = Number(localStorage.getItem('PrintCount'));
    this.printRpt.printerName = localStorage.getItem('PrinterName');
    this.role = sessionStorage.getItem('role');
  }
  pageRefresh() {
    if (this.startDate != null) {
        this.getSalesHistoryByDate(this.startDate);
    } else {
        this.getSalesHistory();
    }
  }
  getSalesHistory(): void {
    this.srv.getSalesHistory()
      .subscribe((resp: SalesHistory[]) => {
        this.salehistory = resp;
      },
        error => console.log('Error :: ' + error)
      );
  }
  getSalesHistoryByDate(startDate: String): void {
    this.srv.getSalesHistoryByDate(startDate)
      .subscribe((resp: SalesHistory[]) => {
        this.salehistory = resp;
      },
        error => console.log('Error :: ' + error)
      );
    sessionStorage.removeItem('orderHistoryStartDate');
  }
  reprocessOrder(id: number, typ: String): void {
    this.srv.reprocessOrderById(id, typ).subscribe();
  }
  voidSales(modal): void {
    if (this.reason.length < 3) {
      this.errorMsg = 'Please enter the reason';
      return null;
    }
    if (this.salesType === 'EMV') {
      this.srv.sendVoidEmvOrder(this.voidSalesId, this.reason).subscribe((resp: boolean) => {
        modal.hide();
      },
        error => console.log('Error :: ' + error)
      );
    } else {
      this.srv.voidSalesById(this.voidSalesId, this.reason)
        .subscribe((resp: Order) => {
          modal.hide();
          this.getSalesHistory();
          if (this.printRpt.printerOption === 'L') {
            this.orderLite = resp;
            this.printRpt.id = this.orderLite.id;
            this.printRpt.createdDate = this.orderLite.createdDate;
            this.printRpt.salesAmount = this.orderLite.salesAmount;
            this.printRpt.sales = this.orderLite.sales;
            this.printRpt.voidReason = this.reason;
            this.srv.printReceipt(this.printRpt).subscribe();
          }
        },
          error => {
            this.errorMsg = 'Not able to process payment... please retry';
          }
        );
    }

  }
  getSaleItems(saleId: number): void {
    this.srv.getSaleItems(saleId).subscribe((resp: Sales[]) => {
      this.sales = resp;
      this.totalAmount = 0.00;
      for (const element of resp) {
        this.totalAmount += (element.price * element.quantity * 100) / 100;
      }
      this.totalAmount = (this.totalAmount * 100) / 100;
    },
      error => console.log('Error :: ' + error)
    );
  }
  printReceipt(saleId: number): void {
    this.srv.rePrint(saleId).subscribe((resp: Order) => {
      if (this.printRpt.printerOption === 'L') {
        this.orderLite = resp;
        this.printRpt.id = this.orderLite.id;
        this.printRpt.createdDate = this.orderLite.createdDate;
        this.printRpt.salesAmount = this.orderLite.salesAmount;
        this.printRpt.sales = this.orderLite.sales;
        this.srv.printReceipt(this.printRpt).subscribe();
      }

    }
    );

  }
  getRefundAmount(salesId: number, amt: number, typ: string): void {
    this.errorMsg = null;
    this.reason = null;
    this.salesType = typ;
    this.voidAmount = amt;
    this.voidSalesId = salesId;
  }
}
