import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ItemService } from '../../services/item.service';
import { Grocery } from 'src/app/models/grocery';
import { KeyValue } from 'src/app/models/keyvalue';

@Component({

    selector: 'app-editgrocery',
    templateUrl: './edit.component.html',
    styleUrls: ['./grocery.component.css']

})

export class EditGroceryComponent implements OnInit {

    grocery: Grocery;
    errorMsg: string;
    storeNames: KeyValue[] = [{key: 'Lotte', value: 'Lotte'}, {key: 'Costco', value: 'Costco'}, {key: 'RD', value: 'RD'}];
    categoryNames: KeyValue[] = [{key: 'Paper', value: 'Paper'}, {key: 'Vegatable', value: 'Vegatable'}, {key: 'Frozen', value: 'Frozen'}];
    constructor(private router: Router,	private route: ActivatedRoute, private itemSrv: ItemService) {
        console.log('EditGroceryComponent: constructor');
    }

    ngOnInit() {
        const selectedId = this.route.snapshot.paramMap.get('id');
        this.grocery = new Grocery();
        if (selectedId != null && Number(selectedId) > 0) {
            this.itemSrv.getGrocery(Number(selectedId))
                .subscribe((resp: Grocery) => {
                    this.grocery = resp;
                    console.log('GroceryComponent: subscribe ' + JSON.stringify(resp));
                },
                    error => {
                        this.errorMsg = 'Invalid onSave';
                        console.log('Error :: ' + this.errorMsg);
                    }
                );
        }
    }

    onSave(): void {
       console.log('GroceryComponent: onSave ' + JSON.stringify(this.grocery));
        this.itemSrv.saveGrocery(this.grocery)
            .subscribe((resp: Grocery) => {
                this.router.navigateByUrl('/grocery');
            },
                error => {
                    this.errorMsg = 'Invalid onSave';
                    console.log('Error :: ' + this.errorMsg);
                }
            );
    }
    setStatusButton(str): void {
        this.grocery.status = str;
        console.log('setStatusButton :: ' + this.grocery.status);
    }
}
