import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styles: [`
        .blue  a {
            background-color: blue !important;
        }
    `]
})
export class NavbarComponent implements OnInit {

    constructor(private router: Router) { }
    role: String;
    ngOnInit() {
        this.role = sessionStorage.getItem('role');
        console.log('HomeComponent: ngOnInit: '+this.role);
    }
}