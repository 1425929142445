import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { UserComponent } from './components/user/user.component';
import { HomeComponent } from './components/home/home.component';
import { LogoutComponent } from './components/login/logout.component';
import { ItemComponent } from './components/item/item.component';
import { OrderComponent } from './components/sales/order.component';
import { AnnapurnaComponent } from './components/sales/annapurna.component';
import { PaymentComponent } from './components/sales/payment.component';
import { StandaloneOrderComponent } from './components/sales/standaloneorder.component';
import { GeneralSalesComponent } from './components/sales/generalsales.component';
import { DisplayComponent } from './components/item/display.component';
import { EdititemComponent } from './components/item/edit.component';
import { EdituserComponent } from './components/user/edit.component';
import { MenuComponent } from './components/item/menu.component';
import { ReportComponent } from './components/reports/report.component';
import { NoticeComponent } from './components/notice/notice.component';
import { OrderHistoryComponent } from './components/reports/orderhistory.component';
import { SummaryComponent } from './components/reports/summary.component';
import { DetailsComponent } from './components/reports/details.component';
import { DeliveryComponent } from './components/reports/delivery.component';
import { SummaryAllComponent } from './components/reports/summaryall.component';
import { DosaComponent } from './components/reports/dosa.component';
import { DeliveryAllComponent } from './components/reports/deliveryall.component';
import { FilterComponent } from './components/reports/filter.component';
import { DateRangeComponent } from './components/reports/daterange.component';
import { SetupComponent } from './components/setup/setup.component';
import { PageNotFoundComponent } from './components/home/page-not-found.component';
import { PaymentCategoryComponent } from './components/paymentcategory/paymentcategory.component';
import { EditPaymentCategoryComponent } from './components/paymentcategory/edit.component';
import { BackupComponent } from './components/reports/backup.component';
import { OrderByDateComponent } from './components/reports/orderbydate.component';
import { GroceryComponent } from './components/grocery/grocery.component';
import { EditGroceryComponent } from './components/grocery/edit.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { EditPurchaseComponent } from './components/purchase/edit.component';
import { PaymentLinkComponent } from './components/paymentlink/paymentlink.component';
import { EditPaymentLinkComponent } from './components/paymentlink/edit.component';
import { OnlineOrderByDateComponent } from './components/reports/onlineorderbydate.component';
import { AveryLabelComponent } from './components/reports/averylabel.component';
import { PaymentPaymentLinkComponent } from './components/paymentlink/payment.component';

const appRoutes: Routes = [
  { path: '', component: AnnapurnaComponent},
  { path: 'user/edit/:id', component: EdituserComponent },
  { path: 'user', component: UserComponent },
  { path: 'notice', component: NoticeComponent },
  { path: 'paymentcategory/edit/:id', component: EditPaymentCategoryComponent },
  { path: 'paymentcategory', component: PaymentCategoryComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'setup', component: SetupComponent },
  { path: 'reports/orderhistory', component: OrderHistoryComponent },
  { path: 'reports/filter', component: FilterComponent },
  { path: 'reports/daterange', component: DateRangeComponent },
  { path: 'reports', component: ReportComponent },
  { path: 'item/edit/:id', component: EdititemComponent },
  { path: 'item', component: ItemComponent },
  { path: 'sales/standaloneorder', component: StandaloneOrderComponent },
  { path: 'sales/order', component: OrderComponent },
  { path: 'sales/payment', component: PaymentComponent },
  { path: 'sales/generalsales', component: GeneralSalesComponent },
  { path: 'sales/annapurna', component: AnnapurnaComponent },
  { path: 'login', component: LoginComponent },
  { path: 'display', component: DisplayComponent },
  { path: 'item/showactive', component: MenuComponent },
  { path: 'reports/backup', component: BackupComponent },
  { path: 'reports/orderbydate', component: OrderByDateComponent },
  { path: 'reports/onlineorderbydate', component: OnlineOrderByDateComponent },
  { path: 'reports/summary', component: SummaryComponent },
  { path: 'reports/details', component: DetailsComponent },
  { path: 'reports/delivery', component: DeliveryComponent },
  { path: 'reports/summaryall', component: SummaryAllComponent },
  { path: 'reports/dosa', component: DosaComponent },
  { path: 'reports/averylabel', component: AveryLabelComponent },
  { path: 'reports/deliveryall', component: DeliveryAllComponent },
  { path: 'grocery/edit/:id', component: EditGroceryComponent },
  { path: 'grocery', component: GroceryComponent },
  { path: 'purchase/edit/:id', component: EditPurchaseComponent },
  { path: 'purchase', component: PurchaseComponent },
  { path: 'paymentlink/edit/:id', component: EditPaymentLinkComponent },
  { path: 'paymentlink/payment/:key', component: PaymentPaymentLinkComponent },
  { path: 'paymentlink', component: PaymentLinkComponent },
  { path: 'home', component: HomeComponent ,
  children: [
    { path: 'notice', component: NoticeComponent },
    { path: 'paymentcategory/edit/:id', component: EditPaymentCategoryComponent },
    { path: 'paymentcategory', component: PaymentCategoryComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'setup', component: SetupComponent },
    { path: 'reports', component: ReportComponent },
    { path: 'reports/orderhistory', component: OrderHistoryComponent },
    { path: 'reports/filter', component: FilterComponent },
    { path: 'reports/summary', component: SummaryComponent },
    { path: 'reports/details', component: DetailsComponent },
    { path: 'reports/delivery', component: DeliveryComponent },
    { path: 'reports/summaryall', component: SummaryAllComponent },
    { path: 'reports/dosa', component: DosaComponent },
    { path: 'reports/averylabel', component: AveryLabelComponent },
    { path: 'reports/deliveryall', component: DeliveryAllComponent },
    { path: 'reports/orderbydate', component: OrderByDateComponent },
    { path: 'reports/onlineorderbydate', component: OnlineOrderByDateComponent },
    { path: 'reports/backup', component: BackupComponent },
    { path: 'item/edit/:id', component: EdititemComponent },
    { path: 'item', component: ItemComponent },
    { path: 'sales/standaloneorder', component: StandaloneOrderComponent },
    { path: 'sales/payment', component: PaymentComponent },
    { path: 'sales/generalsales', component: GeneralSalesComponent },
    { path: 'sales/order', component: OrderComponent },
    { path: 'sales/annapurna', component: AnnapurnaComponent },
    { path: 'login', component: LoginComponent },
    { path: 'display', component: DisplayComponent },
    { path: 'item/showactive', component: MenuComponent },
    { path: 'grocery/edit/:id', component: EditGroceryComponent },
    { path: 'grocery', component: GroceryComponent },
    { path: 'purchase/edit/:id', component: EditPurchaseComponent },
    { path: 'purchase', component: PurchaseComponent },
    { path: 'paymentlink/edit/:id', component: EditPaymentLinkComponent },
    { path: 'paymentlink/payment/:key', component: PaymentPaymentLinkComponent },
    { path: 'paymentlink', component: PaymentLinkComponent },
   ] },
  { path: '**', component: PageNotFoundComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],

})
export class AppRoutingModule { }
