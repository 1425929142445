import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SaleFilter } from '../../models/salefilter';
import { SaleDetails } from '../../models/saledetails';
import { ItemService } from 'src/app/services/item.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./report.component.css']
})
export class DetailsComponent implements OnInit, OnDestroy {

  salefilter: SaleFilter;
  saledetails: SaleDetails[];
  salesItemIds: number[] = [];
  interval: any;
  constructor(private router: Router, private srv: ItemService, private location: Location) {
  }

  ngOnInit() {
    this.salefilter = JSON.parse(sessionStorage.getItem('salefilter'));
    this.getSaleDetails(this.salefilter);
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.getSaleDetails(this.salefilter);
    }, 30000);
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }  
  pageRefresh() {
    location.reload();
  }
  addList(saleItemId: number): void {
    this.salesItemIds.push(saleItemId);
  }
  onSave(saleItemId: number, typ: String): void {
    this.srv.saveSaleItem(saleItemId, typ).subscribe();
    this.getSaleDetails(this.salefilter);
  }
  customerNumber(saleItemId: number): String {
    return saleItemId.toString().substring(saleItemId.toString().length - 2);
  }
  getSaleDetailsRefresh() {
    this.getSaleDetails(this.salefilter);
  }
  getSaleDetails(salefilter: SaleFilter) {
    this.srv.getDetails(salefilter)
      .subscribe((resp: SaleDetails[]) => {
          this.saledetails = resp;
        },
        error => console.log('Error :: ' + error)
      );
  }
}
