import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Item } from '../../models/item';
import { ItemService } from '../../services/item.service';

import { SaleFilter } from '../../models/salefilter';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./report.component.css']
})
export class FilterComponent implements OnInit {

    salefilter: SaleFilter;
    items: Item[];
    reportType: String;
    interval: number;
    errorMsg: String;
    constructor(private router: Router, private srv: ItemService) {
        if (sessionStorage.getItem('role') == null) {
            this.router.navigate(['/login']);
        }

        this.salefilter = new SaleFilter();
        this.salefilter.interval = 8;
        this.reportType = 'S';
        this.salefilter.itemIds = [];
    }

    ngOnInit() {
        this.getItems();
    }
    getItems(): void {
        this.srv.getActiveItems(false)
            .subscribe((resp: Item[]) => {
                    this.items = resp;
                },
                error => console.log('Error :: ' + error)
            );
    }
    setSummaryButton(str): void {
        this.reportType = str;
    }
    setHourButton(str): void {
        this.salefilter.interval = str;
    }
    addList(itemId: number, event): void {
        if (event.target.checked) {
            this.salefilter.itemIds.push(itemId);
            this.items.forEach(element => {
                if (element.id == -1) {
                    this.salefilter.itemIds.pop();
                }
            });
        } else {
            this.items.forEach(element => {
                if (element.id == itemId) {
                    this.salefilter.itemIds.pop();
                }
                if (element.id == -1) {
                    this.salefilter.itemIds.pop();
                }
            });
        }
    }
    selectAll(event): void {
        let status = 'N';
        if (event.target.checked) {
            status = 'Y';
            this.salefilter.itemIds.push(-1);
        }
        this.items.forEach(element => {
            element.showInd = status;
            if (status == 'Y') {
                this.salefilter.itemIds.push(element.id);
            } else {
                this.salefilter.itemIds.pop();
            }
        });
    }
    onSave(): void {
        sessionStorage.setItem('salefilter', JSON.stringify(this.salefilter));
        if (this.reportType == 'S') {
            this.router.navigateByUrl('/reports/summary');
        } else if (this.reportType == 'D') {
            this.router.navigateByUrl('/reports/details');
        } else {
            this.router.navigateByUrl('/reports/delivery');
        }
    }
}
