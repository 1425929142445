import { Component, OnInit } from '@angular/core';
import { Sales } from "../../models/sales";


@Component({
    selector: 'app-display',
    templateUrl: './display.component.html',
    styleUrls: ['./display.component.css']
})

export class DisplayComponent implements OnInit {

    cartObj: Sales[];
    totalCount: number;
    constructor() {
        this.show();
        console.log('DisplayComponent: constructor '+this.totalCount);
    }

    ngOnInit() {
        this.show();
        console.log('DisplayComponent: ngOnInit '+this.totalCount);
    }
    show() {
        console.log('total count: ' );
    }
}
