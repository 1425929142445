import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TokenInterceptor } from './app.interceptor';
import { AppComponent } from './app.component';
import { ModalComponent } from './components/sales/modal.component';
import { ModalsComponent } from './components/sales/modals.component';
import { ModalmComponent } from './components/sales/modalm.component';
import { LoginComponent } from './components/login/login.component';
import { ItemService } from './services/item.service';
import { Nophoto } from './services/nophoto';

import { UserComponent } from './components/user/user.component';
import { HomeComponent } from './components/home/home.component';
import { LogoutComponent } from './components/login/logout.component';
import { ItemComponent  } from './components/item/item.component';
import { OrderComponent } from './components/sales/order.component';
import { StandaloneOrderComponent } from './components/sales/standaloneorder.component';
import { AnnapurnaComponent } from './components/sales/annapurna.component';
import { DisplayComponent } from './components/item/display.component';
import { EdititemComponent } from './components/item/edit.component';
import { EdituserComponent } from './components/user/edit.component';
import { MenuComponent } from './components/item/menu.component';
import { ReportComponent } from './components/reports/report.component';
import { NoticeComponent } from './components/notice/notice.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { RouterComponent } from './components/router/router.component';
import { OrderHistoryComponent } from './components/reports/orderhistory.component';
import { SummaryComponent } from './components/reports/summary.component';
import { DetailsComponent } from './components/reports/details.component';
import { DeliveryComponent } from './components/reports/delivery.component';
import { SummaryAllComponent } from './components/reports/summaryall.component';
import { DosaComponent } from './components/reports/dosa.component';
import { DeliveryAllComponent } from './components/reports/deliveryall.component';
import { FilterComponent } from './components/reports/filter.component';
import { DateRangeComponent } from './components/reports/daterange.component';
import { SetupComponent } from './components/setup/setup.component';
import { AppRoutingModule } from './app-routing.module';
import { PaymentCategoryComponent } from './components/paymentcategory/paymentcategory.component';
import { GeneralSalesComponent } from './components/sales/generalsales.component';
import { EditPaymentCategoryComponent } from './components/paymentcategory/edit.component';
import { NavbarComponent } from './components/home/navbar.component';
import { PageNotFoundComponent } from './components/home/page-not-found.component';
import { PaymentComponent } from './components/sales/payment.component';
import { BackupComponent } from './components/reports/backup.component';
import { OrderByDateComponent } from './components/reports/orderbydate.component';
import { OnlineOrderByDateComponent } from './components/reports/onlineorderbydate.component';
import { GroceryComponent } from './components/grocery/grocery.component';
import { EditGroceryComponent } from './components/grocery/edit.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { EditPurchaseComponent } from './components/purchase/edit.component';
import { AveryLabelComponent } from './components/reports/averylabel.component';
import { PaymentLinkComponent } from './components/paymentlink/paymentlink.component';
import { EditPaymentLinkComponent } from './components/paymentlink/edit.component';
import { PaymentPaymentLinkComponent } from './components/paymentlink/payment.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    UserComponent,
    HomeComponent,
    ItemComponent,
    OrderComponent,
    StandaloneOrderComponent,
    AnnapurnaComponent,
    PaymentComponent,
    DisplayComponent,
    EdititemComponent,
    EdituserComponent,
    MenuComponent,
    BackupComponent,
    OrderByDateComponent,
    OnlineOrderByDateComponent,
    ReportComponent,
    NoticeComponent,
    CatalogComponent,
    ModalComponent,
    ModalsComponent,
    ModalmComponent,
    RouterComponent,
    OrderHistoryComponent,
    SummaryComponent,
    DetailsComponent,
    DeliveryComponent,
    SummaryAllComponent,
    DosaComponent,
    DeliveryAllComponent,
    FilterComponent,
    DateRangeComponent,
    SetupComponent,
    PaymentCategoryComponent,
    EditPaymentCategoryComponent,
    GeneralSalesComponent,
    GroceryComponent,
    EditGroceryComponent,
    PurchaseComponent,
    EditPurchaseComponent,
    AveryLabelComponent,
    NavbarComponent,
    PageNotFoundComponent,
    PaymentLinkComponent,
    EditPaymentLinkComponent,
    PaymentPaymentLinkComponent
  ],

  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  exports: [ NavbarComponent ],
  providers: [ItemService, Nophoto,
   {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}],
  bootstrap: [AppComponent]

})
export class AppModule { }
