import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ItemService } from '../../services/item.service';
import { FormBuilder } from '@angular/forms';

import { PaymentCategory } from '../../models/paymentcategory';

@Component({

  selector: 'app-editpaymentcategory',
  templateUrl: './edit.component.html',
  styleUrls: ['./paymentcategory.component.css']

})

export class EditPaymentCategoryComponent implements OnInit {
  paymentCategory: PaymentCategory;
  errorMsg: string;

  constructor(private router: Router, private route: ActivatedRoute, private itemSrv: ItemService, private formBuilder: FormBuilder) {
    console.log('EditPaymentCategoryComponent: constructor');
  }

  ngOnInit() {
    const selectedId = <number><any>this.route.snapshot.paramMap.get('id');
    let paymentCategory = JSON.parse(sessionStorage.getItem('paymentCategory'));
    console.log('EditPaymentCategoryComponent: ngOnInit 1:' + selectedId + '--');
    if (selectedId > 0) {
      if (paymentCategory != null) {
        for (let i = 0; i < paymentCategory.length; i++) {
          console.log('EditPaymentCategoryComponent: ngOnInit :' + selectedId + '--' + paymentCategory[i].id);
          if (selectedId == paymentCategory[i].id) {
            this.paymentCategory = paymentCategory[i];
            console.log('EditPaymentCategoryComponent: category ' + this.paymentCategory.categoryName + '    ' + this.paymentCategory.status);
            break;
         }
        }
      }
    } else {
      this.paymentCategory = paymentCategory = new PaymentCategory();
    }
    console.log('EditPaymentCategoryComponent: ngOnInit ');
  }

  onSave(): void {
    console.log('EditPaymentCategoryComponent: onSave');
    console.log(' onSave ' + this.paymentCategory);
    this.itemSrv.savePaymentCategory(this.paymentCategory)
      .subscribe(() => {
        this.router.navigateByUrl('/paymentcategory');
      },
        error => {
          this.errorMsg = 'Invalid onSave';
          console.log('Error :: ' + this.errorMsg);
        }
      );
  }
  setStatusButton(str): void {
    this.paymentCategory.status = str;
  }
}
